// Layout Types
import { DefaultLayout, AuthenticationLayout } from "./layouts";

// Route Views
import Logout from "./views/auth/Logout"
import Dashboard from "./views/dashboard/Dashboard"
import PushIDs from "./views/pushids/PushIDs"
import UserProfile from './views/auth/UserProfile'
import ActivityLogs from './views/activityLog/ActivityLogs'
import NotificationsSent from './views/notificationsSent/NotificationsSent'
import NotificationsSilentSent from './views/notificationsSilentSent/NotificationsSilentSent'
import NotificationsTimeline from './views/notificationsTimeline/NotificationsTimeline'
import ManageAlertSettingsCodes from './views/manageAlertSettingsCodes/ManageAlertSettingsCodes'

export default [
  //auth
  {
    path: "/logout",
    layout: AuthenticationLayout,
    component: Logout
  },
  {
    path: "/user-profile",
    layout: DefaultLayout,
    component: UserProfile
  },
  //dashboard
  {
    path: "/dashboard",
    layout: DefaultLayout,
    component: Dashboard
  },
  {
    path: "/activity-logs",
    layout: DefaultLayout,
    component: ActivityLogs
  },
  //pushIDs
  {
    path: "/push-ids",
    layout: DefaultLayout,
    component: PushIDs
  },
  {
    path: "/notifications-sent",
    layout: DefaultLayout,
    component: NotificationsSent
  },
  {
    path: "/notifications-silent-sent",
    layout: DefaultLayout,
    component: NotificationsSilentSent
  },
  {
    path: "/notifications-timeline",
    layout: DefaultLayout,
    component: NotificationsTimeline
  },
  {
    path: "/manage-alert-settings-codes",
    layout: DefaultLayout,
    component: ManageAlertSettingsCodes
  },
];
