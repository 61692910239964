import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  ButtonGroup,
  Button,
  Modal, 
  ModalBody, 
  ModalHeader,
  ModalFooter,
  Tooltip,
} from "shards-react";
import * as AppLocalStorage from "../../../../utils/AppLocalStorage"

const UserActions = (props) => {
  const [username, setUsername] = useState()
  const [logoutModalOpen, setLogoutModalOpen] = useState(false)
  const [logoutTooltipOpen, setLogoutTooltipOpen] = useState(false)
  const [profileTooltipOpen, setProfileTooltipOpen] = useState(false)

  useEffect(() => {
    const user = AppLocalStorage.getLocalStorageFor(AppLocalStorage.LOCAL_STORAGE_KEYS.USER)
    if (user && JSON.parse(user) && JSON.parse(user).username) {
      setUsername(JSON.parse(user).username)
    } else {
      setUsername("Unknown")
    }
  }, []);
  
  return (
    <li style={{ 
      display: "block",
      alignSelf: "center",
      paddingLeft: 10
    }}>
      <ButtonGroup size="sm">
        <Button id="profileBtn" size="sm" theme="white" tag={Link} to="/user-profile">
          <i className="material-icons">perm_identity</i>{' '}{username}{'  '}
        </Button>
        <Button id="logoutBtn" size="sm" theme="white" onClick={() => setLogoutModalOpen(true)}>
          <i className="material-icons">exit_to_app</i>
        </Button>
      </ButtonGroup>
      <Tooltip
        placement="bottom"
        open={logoutTooltipOpen}
        target={"#logoutBtn"}
        toggle={() => setLogoutTooltipOpen(!logoutTooltipOpen)}
      >
        Press here to logout
      </Tooltip>
      <Tooltip
        placement="bottom"
        open={profileTooltipOpen}
        target={"#profileBtn"}
        toggle={() => setProfileTooltipOpen(!profileTooltipOpen)}
      >
        Press here to edit your profile
      </Tooltip>
      <Modal open={logoutModalOpen ? true : false} toggle={() => {}} >
        <ModalHeader>
        <span className="primary">
            <i className="material-icons">warning</i>
          </span>{" "}Logout?
        </ModalHeader>
        <ModalBody>
          <>
          <div><strong>Are you sure do you want to logout?</strong></div>
          </>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup size="sm">
            <Button
              size="sm"
              theme="primary"
              tag={Link} 
              to="/logout"
            >
              <span className="white">
                <i className="material-icons">exit_to_app</i>
              </span>{" "}
            Yes, logout
            </Button>
            <Button
              size="sm"
              theme="white"
              onClick={() => {
                setLogoutModalOpen(false)
              }}
            >
              <span className="white">
                <i className="material-icons">close</i>
              </span>{" "}
            Cancel
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </Modal>
    </li>
  )
}

export default UserActions;