import axios from "axios";
import * as Utils from './Utils'
import * as AppLocalStorage from './AppLocalStorage'
import JSONData from "../apiServerConfig.json"

export const SERVER = {
    PROTOCOL: JSONData.ENDPOINT.PROTOCOL,
    DOMAIN: JSONData.ENDPOINT.DOMAIN,
    PORT: JSONData.ENDPOINT.PORT
}

// export const SERVER = {
//     PROTOCOL: "http://",
//     DOMAIN: "localhost",
//     PORT: "80"
// }

export const API = {
    //auth
    BASE_KEY: "27DO3IOJGFCQ45MLINFY7KIXYGIEYHWZ5RT7755TZBZGFN3RQRZHQ66WLWBWJNRD",
    LOGIN: {
        PATH: "/auth/login/",
    },
    USER_PROFILE: {
        PATH: "/auth/user-profile/",
    },
    //dashboard
    ALL_ACTIVITY_LOGS: {
        PATH: "/service/activity-logs",
    },
    DASHBOARD_STATS: {
        PATH: "/service/dashboard-stats",
    },
    //notification
    ALL_PUSH_ID: {
        PATH: "/service/push-id",
    },
    ALL_PUSH_ID_EVENT_HIST_ENABLED: {
        PATH: "/service/push-id-event-hist-enabled",
    },
    ALL_EVENTS_HISTORY_ENABLED_BY_PUSH_ID: {
        PATH: "/service/events-hist-enabled",
    },
    ALL_EVENTS_HISTORY_ENABLED_BY_SETTINGS_CODE: {
        PATH: "/service/bg-event-history-settings-code",
    },
    ALL_SETTINGS_CODES: {
        PATH: "/service/settings-codes"
    },
    ALL_NOTIFICATIONS_SENT: {
        PATH: "/service/notifications-sent",
    },
    ALL_NOTIFICATIONS_SILENT_SENT: {
        PATH: "/service/notifications-silent-sent",
    },
    ALL_NOTIFICATIONS_SILENT_DETAIL: {
        PATH: "/service/notifications-silent-detail",
    },
    SEND_NOTIFICATION: {
        PATH: "/service/send-notification/"
    },
    GET_PUSH_IDS_BY_SETTINGS_CODE: {
        PREFIX: "service/push-id/",
        SUFFIX: "/bySettingsCode"
    },
    PUSH_ID_EVENTS_OBSERVER: {
        PATH: "/service/pushid-events-observer"
    },
    BG_EVENT_HISTORY_SETTINGS_CODE: {
        PATH: "/service/bg-event-history-settings-code/"
    },
    ALL_APP_EVENTS_WORKING_HOURS: {
        PATH: "/service/app-alert-working-hours"
    },
    ALL_APP_EVENTS_BUSINESS_DAYS: {
        PATH: "/service/app-alert-business-days"
    },
    APP_EVENTS_WORKING_HOURS: {
        PATH: "/service/app-alert-working-hours"
    },
    APP_EVENTS_BUSINESS_DAYS: {
        PATH: "/service/app-alert-business-days"
    },
    APP_ALERT_MESSAGE: {
        PATH: "/service/app-alert-msg"
    }
}

function _getUserToken() {
    const tokenString = AppLocalStorage.getLocalStorageFor(AppLocalStorage.LOCAL_STORAGE_KEYS.SESSION_TOKEN)
    if (Utils.isJson(tokenString)) {
        const tokenJson = JSON.parse(tokenString)
        
        return tokenJson && tokenJson.token ? tokenJson.token : ""
    }

    return ""
}

export function AxiosGET(url, successCallBack, failureCallback) {
    axios({
        method: "get",
        baseURL: SERVER.PROTOCOL + SERVER.DOMAIN + ":" + SERVER.PORT,
        url: url,
        headers: {
            "Authorization": API.BASE_KEY,
            "X-Auth-Token": _getUserToken(),
            "Content-Type": "application/json",
            "Accept": "*/*",
        }
    })
    .then(({ data }) => {
        successCallBack(data)
    })
    .catch((error) => {
        console.log({error})
        if (error && error.response && error.response.status === 401) {
            window.location="/logout"
        } else {
            failureCallback ? failureCallback(error) : successCallBack(error)
        }
    });
}

export function AxiosPUT(url, putParams, successCallBack, failureCallback) {
    axios({
        method: "put",
        baseURL: SERVER.PROTOCOL + SERVER.DOMAIN + ":" + SERVER.PORT,
        url: url,
        data: putParams,
        headers: {
            "Authorization": API.BASE_KEY,
            "X-Auth-Token": _getUserToken(),
            "Content-Type": "application/json",
            "Accept": "*/*",
        }
    })
    .then(( data ) => {
        successCallBack(data)
    })
    .catch((error) => {
        console.log({error})
        failureCallback ? failureCallback(error) : successCallBack(error)
    });
}

export function AxiosPOST(url, postParams, successCallBack, failureCallback) {
    axios({
        method: "post",
        baseURL: SERVER.PROTOCOL + SERVER.DOMAIN + ":" + SERVER.PORT,
        url: url,
        data: postParams,
        headers: {
            "Authorization": API.BASE_KEY,
            "X-Auth-Token": _getUserToken(),
            "Content-Type": "application/json",
            "Accept": "*/*",
        }
    })
    .then(( data ) => {
        successCallBack(data)
    })
    .catch((error) => {
        console.log({error})
        failureCallback ? failureCallback(error) : successCallBack(error)
    });
}

export function AxiosDELETE(url, successCallBack, failureCallback) {
    axios({
        method: "delete",
        baseURL: SERVER.PROTOCOL + SERVER.DOMAIN + ":" + SERVER.PORT,
        url: url,
        headers: {
            "Authorization": API.BASE_KEY,
            "X-Auth-Token": _getUserToken(),
            "Content-Type": "application/json",
            "Accept": "*/*",
        }
    })
    .then(( data ) => {
        successCallBack(data)
    })
    .catch((error) => {
        console.log({error})
        failureCallback ? failureCallback(error) : successCallBack(error)
    });
}

export function AxiosAPNs(apnHeaderParams, bodyParams, successCallBack, failureCallback) {
    const host = "api.sandbox.push.apple.com"
    const apnsId = "eabeae54-14a8-11e5-b60b-1697f925ec7b"
    const axiosRequest = {
        "url": host,
        "baseURL": "",
        "method": "post",
        "headers": {
            "host": host,
            "path": "/3/device/" + apnHeaderParams.deviceToken,
            "authorization": `Bearer MIGTAgEAMBMGByqGSM49AgEGCCqGSM49AwEHBHkwdwIBAQQg5tFBO9d0uQpQMSmm
            exORb+/SXXM4jqCeh4I9XMtLnQegCgYIKoZIzj0DAQehRANCAAR8TbQyYTFxc+PG
            /1PDMerMoWnAubj4p5XtjBtrGyayXj7kfIy9t+UoeLWyOKTn4yr79zqWAUst/1Ui
            pRMulkQU`, 
            "apns-push-type": "alert",
            "apns-id": apnsId, //TODO: is this a constant
            "apns-expiration": 0,
            "apns-priority": 10,
            "apns-topic": apnHeaderParams.apnsTopic
            //,"apns-collapse-id": "" //TODO: what is this?
        },
        "data": {
            "aps": {
               "alert": {
                   "title": bodyParams.title,
                   "subtitle": bodyParams.subtitle,
                   "body": bodyParams.bodyContent
                },
              "sound": "default"
            }
        }
    }
    console.log(axiosRequest)
    axios(axiosRequest)
    .then(( data ) => {
        successCallBack(data)
    })
    .catch((error) => {
        console.log({error})
        failureCallback ? failureCallback(error) : successCallBack(error)
    })
}
