export const LOCAL_STORAGE_KEYS = {
    SESSION_TOKEN: "notification_ui_stk",
    USER: "notification_ui_u",
}

export function getLocalStorageFor(key) {
    return localStorage.getItem(key)
}

export function setLocalStorageFor(key, val) {
    localStorage.setItem(key, val)
}

export function removeLocalStorageFor(key) {
    localStorage.removeItem(key)
}