import React, { useState, useEffect } from "react";
import { 
  Row,
  Col,
  Card,
  CardTitle,
  FormInput,
  CardBody,
  CardFooter,
  ButtonGroup,
  Button,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Modal, 
  ModalBody, 
  ModalHeader,
  ModalFooter,
  Fade,
  Badge,
 } from "shards-react";
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters, 
  useGlobalFilter
} from 'react-table'
import JSONPretty from 'react-json-pretty';
import * as Utils from "../../utils/Utils";
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-json-pretty/themes/monikai.css';
import TableNavigation from './TableNavigation'

function GlobalFilterAndActions({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  mainCardActions,
  disableSearch
}) {
  const count = preGlobalFilteredRows.length

  return (
    <Row>
      <Col lg="7" className="mb-2">
        <InputGroup seamless>
          <InputGroupAddon type="prepend">
            <InputGroupText>
              <span className="primary">
                <i className="material-icons">search</i>
              </span>
            </InputGroupText>
          </InputGroupAddon>
          <FormInput 
            maxLength={100}
            value={globalFilter || ''}
            onChange={e => {
              setGlobalFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            placeholder={`${count} auto notifications detail...`}
            disabled={disableSearch}
          />
        </InputGroup>
      </Col>
      { mainCardActions }
    </Row>
  )
}

function NotificationsSentDetailTableCard({ mainCardActions, columns, data, title, notificationIDColumnID, platformNameColumnID, sentTimeColumnID, myNumberOneColumnID, pushIDColumnID, payloadColumnID, apnsResponseColumnID }) {
  const [contentPayloadElement, setContentPayloadElement] = useState()
  const [contentApnsResponseElement, setContentApnsResponseElement] = useState()

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    allColumns,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      title,
      initialState: { pageIndex: 0 },
    },
    useFilters, 
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  useEffect(() => {
    allColumns.filter((col) => (col.id === 'nsdMyNumberOneForSearchPlus')).map(col => {
      if (col.getToggleHiddenProps().checked) col.toggleHidden()
      return null
    })
    allColumns.filter((col) => (col.id === 'nsdMyNumberOneForSearch00')).map(col => {
      if (col.getToggleHiddenProps().checked) col.toggleHidden()
      return null
    })
    allColumns.filter((col) => (col.id === 'nsdMyNumberOneForSearchFormatted')).map(col => {
      if (col.getToggleHiddenProps().checked) col.toggleHidden()
      return null
    })
  }, [columns]);

  return (
    <>
      <Fade>
        <Card>
          <CardBody>
            <CardTitle>{title}</CardTitle>
            <Modal open={contentPayloadElement ? true : false} toggle={() => {}}>
              <ModalHeader>
                <span className="primary">
                  <i className="material-icons">mail</i>
                </span>{" "}Notification payload
              </ModalHeader>
              <ModalBody>
                <div style={{
                  overflowY: "auto",
                  maxHeight: "50vh",
                }}>
                {
                    contentPayloadElement && 
                    <JSONPretty 
                      id="json-pretty" 
                      data={contentPayloadElement} 
                      onJSONPrettyError={e => console.error(e)}
                      mainStyle="line-height:1.3;color:#7F7F7F;background:#FFFFFF;overflow:auto;"
                      keyStyle="color:#4360BB"
                      valueStyle="color:#5074E3"
                      stringStyle="color:#595C64"
                      booleanStyle="color:#5F4997"
                      errorStyle="color:#B21E1E"
                    />
                }
                </div>
              </ModalBody>
              <ModalFooter>
                <ButtonGroup size="sm">
                  <Button
                    size="sm"
                    theme="white"
                    onClick={() => {
                      setContentPayloadElement()
                    }}
                  >
                    <span className="white">
                      <i className="material-icons">close</i>
                    </span>{" "}
                  Close
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </Modal>

            <Modal open={contentApnsResponseElement ? true : false} toggle={() => {}}>
              <ModalHeader>
                <span className="primary">
                  <i className="material-icons">feedback</i>
                </span>{" "}APNs response
              </ModalHeader>
              <ModalBody>
                <div style={{
                  overflowY: "auto",
                  maxHeight: "50vh",
                }}>
                {
                  contentApnsResponseElement && 
                    <JSONPretty 
                      id="json-pretty" 
                      data={contentApnsResponseElement} 
                      onJSONPrettyError={e => console.error(e)}
                      mainStyle="line-height:1.3;color:#7F7F7F;background:#FFFFFF;overflow:auto;"
                      keyStyle="color:#4360BB"
                      valueStyle="color:#5074E3"
                      stringStyle="color:#595C64"
                      booleanStyle="color:#5F4997"
                      errorStyle="color:#B21E1E"
                    />
                }
                </div>
              </ModalBody>
              <ModalFooter>
                <ButtonGroup size="sm">
                  <Button
                    size="sm"
                    theme="white"
                    onClick={() => {
                      setContentApnsResponseElement()
                    }}
                  >
                    <span className="white">
                      <i className="material-icons">close</i>
                    </span>{" "}
                  Close
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </Modal>

            <div style={{marginBottom: 5}}>
              <GlobalFilterAndActions
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
                mainCardActions={mainCardActions}
                disableSearch={canPreviousPage}
              />
            </div>

            <table {...getTableProps()} className="table mb-0 DFETable numberCol">
              <thead className="bg-light">
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th {...column.getHeaderProps(column.getSortByToggleProps())} scope="col"  className={"border-0" + (column.Header === "My Number" ? " numberCol" : "")}>
                        {column.render('Header')}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? <span className="text-secondary">{' '}<i className="material-icons">keyboard_arrow_down</i></span>
                              : <span className="text-secondary">{' '}<i className="material-icons">keyboard_arrow_up</i></span>
                            : ''}
                        </span>
                        {/* <div>{column.canFilter ? column.render('Filter') : null}</div> */}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()} style={{backgroundColor: Utils.isEven(i) ? "white" : "#FBFBFB"}}>
                      {row.cells.map(cell => {
                        if (cell.column.id === myNumberOneColumnID) {
                          return (
                            <td {...cell.getCellProps()}>
                              <div style={{
                                flexDirection: "row",
                                display: "flex"
                              }}>
                                <div style={{
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyItems: "center",
                                  justifyContent: "center",
                                  marginRight: "10px"
                                }}>
                                  <div style={{
                                    fontSize: 15,
                                    textAlign: "center"
                                  }}>
                                    <span className={cell.row.original[platformNameColumnID] === 'ios' ? "text-primary" : "text-success"} title={cell.row.original[platformNameColumnID]}>
                                      <i className="material-icons">{cell.row.original[platformNameColumnID] === 'ios' ? "phone_iphone" : "android"}</i>
                                    </span>
                                  </div>
                                  <div className={cell.row.original[platformNameColumnID] === 'ios' ? "text-primary" : "text-success"} style={{
                                    fontSize: 10,
                                    textAlign: "center"
                                  }}>
                                    {cell.row.original[platformNameColumnID]}
                                  </div>
                                </div>
                                <div>{ Utils.getPhoneNumberParsed(cell.row.original[myNumberOneColumnID]) }</div>
                              </div>
                            </td>
                          )
                        }
                        if (cell.column.id === notificationIDColumnID) {
                          return (
                            <td {...cell.getCellProps()}>
                              <Badge outline pill theme="info">#{cell.row.original[notificationIDColumnID]}</Badge>
                            </td>
                          )
                        }
                        if (cell.column.id === sentTimeColumnID) {
                          return (
                            <td {...cell.getCellProps()}>
                              { cell.row.original[sentTimeColumnID] }
                            </td>
                          )
                        }
                        if (cell.column.id === pushIDColumnID) {
                          return (
                            <td {...cell.getCellProps()}>
                              <div className="ellipsisWordSM"><span>{cell.row.original[pushIDColumnID]}</span></div>
                            </td>
                          )
                        }
                        if (cell.column.id === payloadColumnID) {
                          return (
                            <td {...cell.getCellProps()}>
                              <ButtonGroup size="sm">
                                <Button theme="dark" onClick={() => setContentPayloadElement(cell.row.original[payloadColumnID])}>
                                  <i className="material-icons">preview</i> silent
                                </Button>
                              </ButtonGroup>
                            </td>
                          )
                        }
                        if (cell.column.id === apnsResponseColumnID) {
                          var responseState
                          var responseLabel
                          switch (cell.row.original['nsdEventStateID']) {
                            case "2":
                              responseState = "success"
                              responseLabel = "Successfull response"
                              break;
                            case "4":
                              responseState = "danger"
                              responseLabel = "Failure response"
                              break;
                            default:
                              responseState = "secondary"
                              responseLabel = "Unknown response"
                          }

                          return (
                            <td {...cell.getCellProps()}>
                              <ButtonGroup size="sm">
                                <Button theme={responseState} onClick={() => setContentApnsResponseElement(cell.row.original[apnsResponseColumnID])}>
                                  <i className="material-icons">pageview</i>{' ' + responseLabel}
                                </Button>
                              </ButtonGroup>
                            </td>
                          )
                        }
                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </CardBody>
          <CardFooter>
            <TableNavigation 
              pageIndex={pageIndex} 
              gotoPage={gotoPage} 
              pageSize={pageSize} 
              pageOptions={pageOptions} 
              setPageSize={setPageSize} 
              canPreviousPage={canPreviousPage} 
              canNextPage={canNextPage}
              previousPage={previousPage} 
              nextPage={nextPage} 
              pageCount={pageCount} 
            />
          </CardFooter>
        </Card>
      </Fade>
    </>
  )
}

export default NotificationsSentDetailTableCard;
