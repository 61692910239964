import React, { useState, useEffect } from "react";
import { 
  Container,
  Row,
  Col,
  Fade,
  ButtonGroup,
  Button,
  Tooltip,
 } from "shards-react";
import { CSVLink } from "react-csv";
import PageTitle from "../../components/common/PageTitle";
import NotificationsSilentSentTableCard from "../../components/table/NotificationsSilentSentTableCard";
import NotificationsSentDetailTableCard from "../../components/table/NotificationsSentDetailTableCard";
import { AxiosGET, API } from "../../utils/BackendAPI";
import * as Utils from "../../utils/Utils"
import CircularProgress from '@material-ui/core/CircularProgress';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const NotificationsSilentSent = (props) => {
  const [autoScroll, setAutoScroll] = useState(false)
  const [dataToExport, setDataToExport] = useState()
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [allNotificationsSilentSent, setAllNotificationsSilentSent] = useState();
  const [allNotificationsSilentDetail, setAllNotificationsSilentDetail] = useState();
  const [processSelected, setSelectedProcess] = useState({});

  const scrollTo = (ref) => {
    if (ref && autoScroll) {
      ref.scrollIntoView({ behavior: 'smooth', block: 'start' })
      setAutoScroll(false)
    }
  }

  const opsColumnName = "Actions"
  const startTimeColumnID = "nssStartTime"
  const endTimeColumnID = "nssEndTime"
  const successColumnID = "nssSuccess"
  const failureColumnID = "nssFailure"

  const NotificationsSilentSentColumns = React.useMemo(
    () => [
      {
        Header: 'Automatic Event Processes',
        columns: [
          {
            Header: 'Process Lifecycle',
          },
        ],
      },
      {
        Header: ' ',
        columns: [
          {
            Header: opsColumnName,
            disableSortBy: true
          }
        ],
      },
    ],
    []
  )

  const notificationIDColumnID = "nsdNotificationID"
  const platformNameColumnID = "nsdPlatformName"
  const myNumberOneColumnID = "nsdMyNumber"
  const pushIDColumnID = "nsdPushID"
  const sentTimeColumnID = "nsdSentTime"
  const payloadColumnID = "nsdPayload"
  const apnsResponseColumnID = "nsdAPNsResponse"
  
  const NotificationsSilentDetailColumns = React.useMemo(
    () => [
      {
        Header: 'Detail',
        columns: [
          {
            Header: 'ID',
            accessor: notificationIDColumnID,
          },
          {
            Header: 'Sent time',
            accessor: sentTimeColumnID,
          },
          {
            Header: 'My Number 1',
            accessor: myNumberOneColumnID,
          },
          {
            Header: 'nsdMyNumberOneForSearchPlus',
            accessor: 'nsdMyNumberOneForSearchPlus',
          },
          {
            Header: 'nsdMyNumberOneForSearch00',
            accessor: 'nsdMyNumberOneForSearch00',
          },
          {
            Header: 'nsdMyNumberOneForSearchFormatted',
            accessor: 'nsdMyNumberOneForSearchFormatted',
          },
          {
            Header: 'Push ID',
            accessor: pushIDColumnID,
          },
          {
            Header: 'Payload',
            accessor: payloadColumnID,
          },
          {
            Header: 'APNs Response',
            accessor: apnsResponseColumnID,
          },
        ],
      },
    ],
    []
  )

  const mainCardActions = (
    <Col lg="6" className="mb-2" style={{
      display: "flex",
      justifyContent: "flex-end"
    }}>
      <ButtonGroup size="sm">
        <CSVLink
          data={dataToExport || []}
          filename={"AutoBackgroundNotifications.csv"}
        >
          <Button id="exportBtn" theme="secondary" onClick={() => {}}>
            <span className="text-white">
              <i className="material-icons">file_upload</i>
            </span>{" "}
            Export
          </Button>
          <Tooltip
            placement="bottom"
            open={tooltipOpen}
            target={"#exportBtn"}
            toggle={() => setTooltipOpen(!tooltipOpen)}
          >
            Export to a .csv file
          </Tooltip>
        </CSVLink>
      </ButtonGroup>
    </Col>
  )

  const mainCardActionsDetail = (
    <Col lg="5" className="mb-2" style={{
      display: "flex",
      justifyContent: "flex-end"
    }}>
      <ButtonGroup size="sm" style={{marginLeft: 10}}>
        <Button
          size="sm"
          theme="secondary"
          onClick={() => fnCloseDetail()}
        >
          <span className="white">
            <i className="material-icons">close</i>
          </span>{" "}
          Close
        </Button>
      </ButtonGroup>
    </Col>
  )

  useEffect(() => {
    fnRenderTable()
  }, []);

  useEffect(() => {
    var dataToExportArr = []
    dataToExportArr.push(["Start Time", "End Time", "Success Count", "Failure Count"])
    if (allNotificationsSilentSent) {
      allNotificationsSilentSent.forEach(item => {
        dataToExportArr.push([item.nssStartTime, item.nssEndTime, item.nssSuccess, item.nssFailure])
      })
    }

    setDataToExport(dataToExportArr)
  }, [allNotificationsSilentSent]);

  function fnRenderTable() {
    AxiosGET(API.ALL_NOTIFICATIONS_SILENT_SENT.PATH, (data) => {
      setAllNotificationsSilentSent(data)
    }, 
    (e) => toast.error("It was not possible to load the sent silent notifications"))
  }

  function fnOpenDetail(startProcess, success) {
    AxiosGET(API.ALL_NOTIFICATIONS_SILENT_DETAIL.PATH + "/" + startProcess + "/" + (success ? 2 : 4), (data) => {
      data.map(d => {
        d = Object.assign(d, {
          nsdMyNumberOneForSearchPlus: Utils.getNumberWithPrefixPlus(d.nsdMyNumber),
          nsdMyNumberOneForSearch00: Utils.getNumberWithPrefix00(d.nsdMyNumber),
          nsdMyNumberOneForSearchFormatted: Utils.getPhoneNumberParsed(d.nsdMyNumber)
        })

        return d
      })
      setAllNotificationsSilentDetail(data)

      if (data && data.length > 0) {
        setSelectedProcess({
          startProcess, 
          success
        })
        setAutoScroll(true)
        scrollTo()
      } else {
        setSelectedProcess({})
      }
    }, 
    (e) => toast.error("It was not possible to load the sent silent notifications detail"))
  }

  function fnCloseDetail() {
    setAllNotificationsSilentDetail()
    setSelectedProcess({})
  }

  const header = (
    <Row noGutters className="page-header py-4">
      <PageTitle title="Automatic Notifications" subtitle="Notification UI" className="text-sm-left mb-3" />
    </Row>
  )

  if (!allNotificationsSilentSent) {
    return (
      <Container fluid className="main-content-container px-4">
        {header}
        <CircularProgress />
      </Container>
    )
  }

  return (
    <Container fluid className="main-content-container px-4">
      {header}
      <Fade>
        {
          allNotificationsSilentSent &&
          <Row>
            <Col lg="10" className="mb-4">
              <NotificationsSilentSentTableCard columns={NotificationsSilentSentColumns} data={allNotificationsSilentSent} title="Automatic Event Processes" successColumnID={successColumnID} failureColumnID={failureColumnID} startTimeColumnID={startTimeColumnID} endTimeColumnID={endTimeColumnID} mainCardActions={mainCardActions} opsColumnName={opsColumnName} openDetail={fnOpenDetail} closeDetail={fnCloseDetail} processSelected={processSelected} />
            </Col>
          </Row>
        }
        <div ref={scrollTo}>
          <Row>
            {
              allNotificationsSilentDetail &&
              <Col lg="12" className="mb-4">
                <NotificationsSentDetailTableCard columns={NotificationsSilentDetailColumns} data={allNotificationsSilentDetail} title={(processSelected && processSelected.startProcess ? ("Detail of process started at " + processSelected.startProcess) : "There is no detail available" )} mainCardActions={mainCardActionsDetail} notificationIDColumnID={notificationIDColumnID} platformNameColumnID={platformNameColumnID} sentTimeColumnID={sentTimeColumnID} myNumberOneColumnID={myNumberOneColumnID} pushIDColumnID={pushIDColumnID} payloadColumnID={payloadColumnID} apnsResponseColumnID={apnsResponseColumnID}  />
              </Col>
            }
          </Row>
        </div>
      </Fade>
  
    </Container>
  )
}

export default NotificationsSilentSent;
