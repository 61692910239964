import React from "react";
import { 
  Row,
  Col,
  Card,
  CardTitle,
  FormInput,
  CardBody,
  CardFooter,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Fade,
  Badge,
 } from "shards-react";
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters, 
  useGlobalFilter
} from 'react-table'
import * as Utils from "../../utils/Utils";
import TableNavigation from './TableNavigation'
import 'react-toastify/dist/ReactToastify.min.css';

const CustomStepConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#AFFBD6',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#AFFBD6',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 2,
    borderRadius: 1,
  },
})(StepConnector);

function getNotificationTimelineSteps(startDate, endDate) {
  return [startDate, "Permissions", "Assistant", "Read Calendar", "Updated", endDate !== "0" ? endDate : "End Date"];
}

/*function getActiveStep(permissions, assistant, readCalendar, updated, endDate) {
  var step = 0

  if (permissions === "1") {
    step = 1
  }
  if (assistant === "1") {
    step = 2
  }
  if (permissions === "1") {
    step = 3
  }
  if (readCalendar === "1") {
    step = 4
  }
  if (updated === "1") {
    step = 5
  }
  if (endDate !== "0") {
    step = 6
  }

  return step
}*/

function GlobalFilterAndActions({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  globalFilteredRows,
  mainCardActions,
  disableSearch,
}) {
  const count = preGlobalFilteredRows.length

  return (
    <Row>
      <Col lg="7" className="mb-2">
        <InputGroup seamless>
          <InputGroupAddon type="prepend">
            <InputGroupText>
              <span className="primary">
                <i className="material-icons">search</i>
              </span>
            </InputGroupText>
          </InputGroupAddon>
          <FormInput 
            maxLength={100}
            value={globalFilter || ''}
            onChange={e => {
              setGlobalFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            placeholder={`${count} push users...`}
            disabled={disableSearch}
          />
        </InputGroup>
      </Col>
      {mainCardActions}
    </Row>
  )
}

function EventsHistoryEnabledByPushIDTableCard({ columns, data, title, mainCardActions, notificationIDColumnID, startDateColumnID, permissionsColumnID, assistantColumnID, readCalendarColumnID, updatedColumnID, endDateColumnID }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    // allColumns,
    globalFilteredRows,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      title,
      initialState: { pageIndex: 0 },
    },
    useFilters, 
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  return (
    <>
      <Fade>
        <Card>
          <CardBody>
            <CardTitle>{title}</CardTitle>

            <div style={{marginBottom: 5}}>
              <GlobalFilterAndActions
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
                mainCardActions={mainCardActions}
                disableSearch={canPreviousPage}
                globalFilteredRows={globalFilteredRows}
              />
            </div>

            <table {...getTableProps()} className="table mb-0 DFETable">
              <thead className="bg-light">
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th {...column.getHeaderProps(column.getSortByToggleProps())} scope="col" className="border-0">
                        {column.render('Header')}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? <span className="text-secondary">{' '}<i className="material-icons">keyboard_arrow_down</i></span>
                              : <span className="text-secondary">{' '}<i className="material-icons">keyboard_arrow_up</i></span>
                            : ''}
                        </span>
                        {/* <div>{column.canFilter ? column.render('Filter') : null}</div> */}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()} style={{backgroundColor: Utils.isEven(i) ? "white" : "#FBFBFB"}}>
                      {row.cells.map(cell => {
                        
                        const notificationTimelineSteps = getNotificationTimelineSteps(cell.row.original[startDateColumnID], cell.row.original[endDateColumnID])
                        return (
                          <td {...cell.getCellProps()}>
                            <div style={{
                              display: "flex",
                              flexDirection: "column"
                            }}>
                              <div>
                                <Badge outline pill theme="info">#{cell.row.original[notificationIDColumnID]}</Badge>
                              </div>
                              <div className="notificationTimelineStepsWrapper">
                                <Stepper  connector={<CustomStepConnector />} alternativeLabel>
                                  {notificationTimelineSteps.map((label, index) => {
                                    const stepProps = {};
                                    const labelProps = {};

                                    if (index === 0 && cell.row.original[startDateColumnID] !== "0") {
                                      stepProps.completed = true
                                    } else if (index === 1 && cell.row.original[permissionsColumnID] === "1") {
                                      stepProps.completed = true
                                    } else if (index === 2 && cell.row.original[assistantColumnID] === "1") {
                                      stepProps.completed = true
                                    } else if (index === 3 && cell.row.original[readCalendarColumnID] === "1") {
                                      stepProps.completed = true
                                    } else if (index === 4 && cell.row.original[updatedColumnID] === "1") {
                                      stepProps.completed = true
                                    } else if (index === 4 && cell.row.original[updatedColumnID] === "1" && cell.row.original[endDateColumnID] === "0") {
                                      stepProps.completed = true
                                    } else if (index === 4 && cell.row.original[updatedColumnID] === "0" && cell.row.original[endDateColumnID] !== "0") {
                                      stepProps.completed = false
                                    } else if (index === 5 && cell.row.original[endDateColumnID] !== "0") {
                                      stepProps.completed = true
                                    }
                                    return (
                                      <Step key={label} {...stepProps}>
                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                      </Step>
                                    );
                                  })}
                                </Stepper>
                              </div>
                            </div>
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </CardBody>
          <CardFooter>
            <TableNavigation 
              pageIndex={pageIndex} 
              gotoPage={gotoPage} 
              pageSize={pageSize} 
              pageOptions={pageOptions} 
              setPageSize={setPageSize} 
              canPreviousPage={canPreviousPage} 
              canNextPage={canNextPage}
              previousPage={previousPage} 
              nextPage={nextPage} 
              pageCount={pageCount} 
            />
          </CardFooter>
        </Card>
      </Fade>
    </>
  )
}

export default EventsHistoryEnabledByPushIDTableCard;
