
import React, { useState, useEffect } from "react";
import { 
  Container, 
  Row, 
  Col, 
  Card, 
  CardHeader,
  CardTitle, 
  CardBody, 
  CardFooter,
  ButtonGroup,
  Button,
  FormInput,
  ListGroup,
  ListGroupItem,
  FormGroup,
  FormFeedback,
  Fade,
} from "shards-react";
import { AxiosPUT, API } from "../../utils/BackendAPI";
import * as AppLocalStorage from "../../utils/AppLocalStorage"
import PageTitle from "../../components/common/PageTitle";
import { useAuth } from "../../context/auth";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import * as Utils from "../../utils/Utils"

const UserProfile = (props) => {
  const [formIsValid, setFormIsValid] = useState(true);
  const [username, setUsername] = useState();
  const [oldPassword, setOldPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState();
  const { setServiceSuccess } = useAuth()

  useEffect(() => {
    const userStr = AppLocalStorage.getLocalStorageFor(AppLocalStorage.LOCAL_STORAGE_KEYS.USER)
    if (userStr) {
      setUsername(JSON.parse(userStr).username)
      setFormIsValid(isFormValid()) 
    }
  }, []);

  const handleChangeOldPassword = e => {
    setOldPassword(e.target.value)
    setFormIsValid(isFormValid()) 
  }

  const handleChangeNewPassword = e => {
    setNewPassword(e.target.value)
    setFormIsValid(isFormValid()) 
  }

  const handleChangeNewPasswordConfirmation = e => {
    setNewPasswordConfirmation(e.target.value)
    setFormIsValid(isFormValid()) 
  }

  const isFormValid = () => {
    return username && oldPassword && newPassword && newPasswordConfirmation && (newPassword === newPasswordConfirmation) && newPassword.length >= 3
  }

  function editUserProfile() {
    if (username && oldPassword && newPassword && newPasswordConfirmation && (newPassword === newPasswordConfirmation)) {
      setFormIsValid(true)
      AxiosPUT(API.USER_PROFILE.PATH, {"username":username,"oldPassword":oldPassword,"newPassword":newPassword}, ({data}) => {
        setServiceSuccess("Your password was successfuly updated!")
        setTimeout(() => props.history.goBack(), 200)
      }, (e) => {
        if (e && e.response && e.response.data && e.response.data.error) {
          toast.error(e.response.data.error)
        } else {
          toast.error(Utils.getFriendlyError(e.response.status))
        }
      })
    } else {
      setFormIsValid(false)
    }
  }

  const header = (
    <Row noGutters className="page-header py-4">
      <PageTitle sm="4" title="Edit user profile" subtitle="Notification UI" className="text-sm-left" />
    </Row>
  )

  if (!username) {
    return (
      <Container fluid className="main-content-container px-4">
        {header}
      </Container>
    )
  }

  return (
    <Container fluid className="main-content-container px-4">
      {header}
      <Fade>
        <Row>
          <Col lg="6" className="mb-4">
            <Card small className="mb-4">
              <CardHeader><CardTitle>Edit profile</CardTitle></CardHeader>
              <CardBody className="p-0 pb-3">
                <Row>
                  {/* Editor */}
                  <Col lg="12" md="12">
                    <ListGroup flush>
                      <ListGroupItem className="px-3">
                        <strong className="text-muted d-block mb-2">
                          Username
                        </strong>
                        <FormGroup>
                          <FormInput disabled={true} maxLength={50} value={username || ''} size="lg" className="mb-2" placeholder="username" required invalid={!formIsValid && !username} />
                          <FormFeedback valid={formIsValid && !!username}>Username is mandatory.</FormFeedback>
                        </FormGroup>

                        <strong className="text-muted d-block mb-2">
                          Old password
                        </strong>
                        <FormGroup>
                          <FormInput type="password" maxLength={100} value={oldPassword || ''} size="lg" className="mb-2" placeholder="password" required invalid={!oldPassword} onChange={handleChangeOldPassword} />
                          <FormFeedback valid={!!oldPassword}>Old password is mandatory.</FormFeedback>
                        </FormGroup>

                        <strong className="text-muted d-block mb-2">
                          New password
                        </strong>
                        <FormGroup>
                          <FormInput type="password" maxLength={30} value={newPassword || ''} size="lg" className="mb-2" placeholder="password" required invalid={!newPassword || newPassword.length < 3} onChange={handleChangeNewPassword} />
                          <FormFeedback valid={!!newPassword}>New password is mandatory.</FormFeedback>
                          <FormFeedback valid={!!newPassword && newPassword.length >= 3}>New password must have at least 3 characters.</FormFeedback>
                        </FormGroup>

                        <strong className="text-muted d-block mb-2">
                          New password confirmation
                        </strong>
                        <FormGroup>
                          <FormInput type="password" maxLength={30} value={newPasswordConfirmation || ''} size="lg" className="mb-2" placeholder="password" required invalid={(!newPasswordConfirmation || (newPasswordConfirmation !== newPassword)) && !formIsValid} onChange={handleChangeNewPasswordConfirmation} />
                          <FormFeedback valid={!!newPasswordConfirmation}>New password confirmation is mandatory.</FormFeedback>
                          <FormFeedback valid={!!newPasswordConfirmation && (newPasswordConfirmation === newPassword)}>Password confirmation must be equal to the new password.</FormFeedback>
                        </FormGroup>
                      </ListGroupItem>
                    </ListGroup>
                  </Col>
                </Row>
              </CardBody>
                <CardFooter className="border-top">
                  <ButtonGroup size="sm">
                    <Button
                      size="sm"
                      theme="primary"
                      onClick={() => {
                        editUserProfile()
                      }}
                    >
                      <span className="primary">
                        <i className="material-icons">check</i>
                      </span>{" "}
                    Update
                    </Button>
                    <Button
                      size="sm"
                      theme="white"
                      onClick={() => {
                        props.history.goBack()
                      }}
                    >
                      <span className="white">
                        <i className="material-icons">close</i>
                      </span>{" "}
                    Cancel
                    </Button>
                  </ButtonGroup>
                </CardFooter>
            </Card>
          </Col>
        </Row>
      </Fade>
    </Container>
  );
}

export default UserProfile;
