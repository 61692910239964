import React, {useState} from "react";
import { 
  Row,
  Col,
  Card,
  CardTitle,
  CardBody,
  CardFooter,
  ButtonGroup,
  Button,
  Fade,
  Slider
 } from "shards-react";
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters, 
  useGlobalFilter
} from 'react-table'
import * as Utils from "../../utils/Utils";
import TableNavigation from './TableNavigation'
import 'react-toastify/dist/ReactToastify.min.css';

function GlobalFilterAndActions({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  globalFilteredRows,
  mainCardActions,
  disableSearch,
  fnAddWorkingHours
}) {

  const [slideValue, setSlideValue] = useState([9, 18])

  return (
    <>
      <Row>
        <Col lg="12">
          <Slider
            connect
            pips={{ mode: "steps", stepped: true, density: 10 }}
            onSlide={(v) => setSlideValue([parseFloat(v[0]), parseFloat(v[1])])}
            start={slideValue}
            step={1}
            range={{ min: 0, max: 23 }}
          />
        </Col>
      </Row>
      <Row>
        <Col lg="12" className="mb-2 mt-2" style={{
          display: "flex",
          justifyContent: "flex-end",
        }}>
          <ButtonGroup size="sm" style={{marginLeft: 10}}>
            <Button
              size="sm"
              theme="primary"
              onClick={() => fnAddWorkingHours(slideValue[0], slideValue[1])}
            >
              <span className="white">
                <i className="material-icons">add</i>
              </span>{" "}
              Add {slideValue[0] + "h - " + slideValue[1] + "h"}
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </>
  )
}

function AppEventsSettinsCodesWorkingHoursTableCard({ columns, data, title, opsColumnName, nav, settingsCodeEnabledColumnID, mainCardActions, fnAddWorkingHours, fnRemoveWorkingHours }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    globalFilteredRows,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      title,
      initialState: { pageIndex: 0 },
    },
    useFilters, 
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  return (
    <>
      <Fade>
        <Card>
          <CardBody>
            <CardTitle>{title}</CardTitle>

            <div style={{marginBottom: 5}}>
              <GlobalFilterAndActions
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
                mainCardActions={mainCardActions}
                disableSearch={canPreviousPage}
                globalFilteredRows={globalFilteredRows}
                fnAddWorkingHours={fnAddWorkingHours}
              />
            </div>

            <table {...getTableProps()} className="table mb-0 DFETable tableWithActionsColumnIdx3">
              <thead className="bg-light">
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th {...column.getHeaderProps(column.getSortByToggleProps())} scope="col" className="border-0">
                        {column.render('Header')}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? <span className="text-secondary">{' '}<i className="material-icons">keyboard_arrow_down</i></span>
                              : <span className="text-secondary">{' '}<i className="material-icons">keyboard_arrow_up</i></span>
                            : ''}
                        </span>
                        {/* <div>{column.canFilter ? column.render('Filter') : null}</div> */}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()} style={{backgroundColor: Utils.isEven(i) ? "white" : "#FBFBFB"}}>
                      {row.cells.map(cell => {
                        if (cell.column.Header === opsColumnName) {
                          return (
                            <td {...cell.getCellProps()}>
                              <div className="actionsColumn">
                                <ButtonGroup size="sm">
                                  <Button theme="danger" onClick={() => {
                                    fnRemoveWorkingHours(cell.row.original["whID"])
                                  }}>
                                    <span className="text-white"><i className="material-icons">delete</i>{' '}</span>
                                  </Button> 
                                </ButtonGroup>
                              </div>
                            </td>
                          )
                        }
                        if (cell.column.id === "whHourBegin") {
                          return (
                            <td {...cell.getCellProps()} wd-id-begin={cell.row.original["whID"]}>
                              {cell.row.original["whHourBegin"]}h
                            </td> 
                          )
                        }
                        if (cell.column.id === "whHourEnd") {
                          return (
                            <td {...cell.getCellProps()} wd-id-end={cell.row.original["whID"]}>
                              {cell.row.original["whHourEnd"]}h 
                            </td>
                          )
                        }
                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </CardBody>
          <CardFooter>
            <TableNavigation 
              pageIndex={pageIndex} 
              gotoPage={gotoPage} 
              pageSize={pageSize} 
              pageOptions={pageOptions} 
              setPageSize={setPageSize} 
              canPreviousPage={canPreviousPage} 
              canNextPage={canNextPage}
              previousPage={previousPage} 
              nextPage={nextPage} 
              pageCount={pageCount} 
            />
          </CardFooter>
        </Card>
      </Fade>
    </>
  )
}

export default AppEventsSettinsCodesWorkingHoursTableCard;
