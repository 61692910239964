import React from "react";
import PropTypes from "prop-types";
import Chart from "../utils/chart";

class ChartDashboardAVGAutoObserverLastMonth extends React.Component {
  constructor(props) {
    super(props);

    this.canvasRef = React.createRef();
  }

  componentDidMount() {
    this._buildChart(this.props.globalData)
  }

  componentWillReceiveProps(props) {
    this._buildChart(props.globalData)
  }

  _buildChart(globalData) {
    const labels = ["Start Date", "Permissions", "Assistant", "Read Calendar", "Updated", "End Date", "End Date (no Updated)"]
    var totals = []
    var total = 0

    if (globalData && globalData.stageAutoObserverStats && globalData.stageAutoObserverStats.length > 0) {
      const stats = globalData.stageAutoObserverStats[0]
      total = parseInt(stats.saoStartDate) + parseInt(stats.saoPermissions) + parseInt(stats.saoAssistant) + parseInt(stats.saoReadCalendar) + parseInt(stats.saoUpdated) + parseInt(stats.saoEndDate) + parseInt(stats.saoEndDateWithoutUpdated)

      console.log({stats})
      console.log({total})

      const startDatePercentage = (stats.saoStartDate * 100) / total
      const permissionsPercentage = (stats.saoPermissions * 100) / total
      const assistantPercentage = (stats.saoAssistant * 100) / total
      const readCalendarPercentage = (stats.saoReadCalendar * 100) / total
      const updatedPercentage = (stats.saoUpdated * 100) / total
      const endDatePercentage = (stats.saoEndDate * 100) / total
      const endDateWithoutUpdatedPercentage = (stats.saoEndDateWithoutUpdated * 100) / total

      console.log({startDatePercentage})
      console.log({permissionsPercentage})
      console.log({assistantPercentage})
      console.log({readCalendarPercentage})
      console.log({updatedPercentage})
      console.log({endDatePercentage})
      console.log({endDateWithoutUpdatedPercentage})

      totals.push(startDatePercentage ? startDatePercentage.toFixed(2) : 0)
      totals.push(permissionsPercentage ? permissionsPercentage.toFixed(2) : 0)
      totals.push(assistantPercentage ? assistantPercentage.toFixed(2) : 0)
      totals.push(readCalendarPercentage ? readCalendarPercentage.toFixed(2) : 0)
      totals.push(updatedPercentage ? updatedPercentage.toFixed(2): 0)
      totals.push(endDatePercentage ? endDatePercentage.toFixed(2): 0)
      totals.push(endDateWithoutUpdatedPercentage ? endDateWithoutUpdatedPercentage.toFixed(2): 0)

      console.log({totals})
    }

    var chartData = {
      datasets: [
        {
          hoverBorderColor: "#ffffff",
          data: totals,
          backgroundColor: [
            "#C15444",
            "#C18244",
            "#BFBD40",
            "#65C144",
            "#40BF99",
            "#4491C1",
            "#304d99"
          ]
        }
      ],
      labels: labels
    }
    
    this._renderChart(chartData)
  }

  _renderChart(chartData) {
    const chartConfig = {
      type: "pie",
      data: chartData,
      options: {
        ...{
          legend: {
            position: "bottom",
            labels: {
              padding: 25,
              boxWidth: 20
            }
          },
          cutoutPercentage: 40,
          tooltips: {
            custom: false,
            mode: "index",
            position: "nearest"
          }
        },
        ...this.props.chartOptions
      }
    };

    this.setState({
      tasksMap: this.props.tasksMap
    })

    new Chart(this.canvasRef.current, chartConfig);
  }

  render() {
    return (
      <canvas
        height="220"
        ref={this.canvasRef}
        className="blog-users-by-device m-auto"
      />
    );
  }
}

ChartDashboardAVGAutoObserverLastMonth.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
  /**
   * The chart config object.
   */
  chartConfig: PropTypes.object,
  /**
   * The Chart.js options.
   */
  chartOptions: PropTypes.object,
  /**
   * The chart data.
   */
  chartData: PropTypes.object
};

export default ChartDashboardAVGAutoObserverLastMonth;
