import React from "react";
import { Redirect } from "react-router-dom";
import { useAuth } from "../../context/auth";

import Cookies from 'js-cookie';

const Logout = (props) => {
  const { setAuthTokens } = useAuth();

  setAuthTokens();
  Cookies.remove('__session')
  return <Redirect to={"/"} />;
}

export default Logout;