export default function() {
  return [
    {
      title: "Dashboard",
      htmlBefore: '<i class="material-icons">dashboard</i>',
      to: "/dashboard",
    },
    {
      title: "System Activity Logs",
      htmlBefore: '<i class="material-icons">dvr</i>',
      to: "/activity-logs",
    },
    { separator: true },
    {
      title: "Push Users",
      htmlBefore: '<i class="material-icons">tap_and_play</i>',
      to: "/push-ids",
    },
    {
      title: "Sent Notifications",
      htmlBefore: '<i class="material-icons">featured_play_list</i>',
      to: "/notifications-sent",
    },
    { separator: true },
    {
      title: "Automatic Events Push",
      htmlBefore: '<i class="material-icons">app_settings_alt</i>',
      to: "/notifications-silent-sent",
    },
    {
      title: "Automatic Events Timeline",
      htmlBefore: '<i class="material-icons">timeline</i>',
      to: "/notifications-timeline",
    },
    { separator: true },
    {
      title: "Manage Alert Settings Codes",
      htmlBefore: '<i class="material-icons">notification_important</i>',
      to: "/manage-alert-settings-codes",
    },
  ];
}
