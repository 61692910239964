import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import {
  Card,
  CardBody,
  FormGroup,
  Form,
  FormFeedback,
  FormInput,
  Button,
  Fade,
} from "shards-react";
import { useAuth } from "../../context/auth";
import Cookies from 'js-cookie';
import { API, AxiosPOST } from "../../utils/BackendAPI";
import * as Utils from "../../utils/Utils";
import * as AppLocalStorage from "../../utils/AppLocalStorage"

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const ComponentsOverview = (props) => {
  const [formIsValid, setFormIsValid] = useState(true);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { authTokens, setAuthTokens } = useAuth();
  const referer = (props.location && props.location.state && props.location.state.referer) || '/';

  function postLogin() {
    if (username && password) {
      setFormIsValid(true)
      AxiosPOST(API.LOGIN.PATH, {username, password}, (result) => {
        if (result.status === 200 && !Utils.isObjectEmpty(result.data)) {
          var json = JSON.parse(JSON.stringify(result.data))
  
          AppLocalStorage.setLocalStorageFor(AppLocalStorage.LOCAL_STORAGE_KEYS.USER, JSON.stringify({"username": username}))
          setAuthTokens(result.data);
          Cookies.set('__session', json.token)
        } else {
          AppLocalStorage.removeLocalStorageFor(AppLocalStorage.LOCAL_STORAGE_KEYS.USER)
          toast.error("It was not possible to process your request");
          console.warn(JSON.stringify(result.status))
        }
      }, (e) => {
        AppLocalStorage.removeLocalStorageFor(AppLocalStorage.LOCAL_STORAGE_KEYS.USER)
        if (e && e.response && e.response.data && e && e.response && e.response.data.error) {
          toast.error(e.response.data.error);
        } else {
          toast.error("It was not possible to process your request");
        }
      })
    } else {
      setFormIsValid(false)
    }
  }

  const handleChangeUsername = e => {
    setUsername(e.target.value)
    setFormIsValid(e.target.value ? true : false) 
  }

  const handleChangePassword = e => {
    setPassword(e.target.value)
    setFormIsValid(e.target.value ? true : false) 
  }

  if (authTokens) {
    return <Redirect to={referer} />;
  }

  return (
    <div className="loginCard">
      <Fade>
        <Card large="true" style={{width: 300, marginTop: 100}}>
          <CardBody>
              <div style={{
                justifyContent: "center", 
                display: "flex",
                marginBottom: 20
              }}>
                <img
                  id="main-logo"
                  style={{ width: 50, height: 50 }}
                  src={require("../../images/everdelta.png")}
                  alt="Notification UI"
                />
              </div>
            <Form>
              <FormGroup>
                <label htmlFor="#username">Username</label>
                <FormInput maxLength={50} id="#username" placeholder="Username" required invalid={!username && !formIsValid} onChange={handleChangeUsername} />
                <FormFeedback valid={formIsValid}>Username is mandatory.</FormFeedback>
              </FormGroup>
              <FormGroup>
                <label htmlFor="#password">Password</label>
                <FormInput maxLength={255} type="password" id="#password" placeholder="Password" required invalid={!password && !formIsValid} onChange={handleChangePassword} />
                <FormFeedback valid={formIsValid}>Password is mandatory.</FormFeedback>
              </FormGroup>
            </Form>
            <Button onClick={postLogin}>
              <i className="material-icons">lock_open</i>{' '}Login
            </Button>
          </CardBody>
        </Card>
      </Fade>
    </div>
  )
};

export default ComponentsOverview;
