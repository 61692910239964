import { parsePhoneNumberFromString, AsYouType } from 'libphonenumber-js'

export function isObjectEmpty(o) {
    return o === undefined || o === null || (Object.entries(o).length === 0 && o.constructor === Object)
}

export function isEven(n) {
    return n % 2 === 0;
}

export function isJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function getCSVObjFromFile(allText) {
    var allTextLines = allText.split(/\r\n|\n/);
    var headers = allTextLines[0].split(',');
    var lines = [];

    for (var i=1; i<allTextLines.length; i++) {
        var data = allTextLines[i].split(',');
        if (data.length === headers.length) {

            var tarr = [];
            for (var j=0; j<headers.length; j++) {
                tarr.push(headers[j]+":"+data[j]);
            }
            lines.push(tarr);
        }
    }
    return lines
}

export function getFileAsText(fileToRead, loadHandler, errorHandler) {
    var reader = new FileReader();
    // Read file into memory as UTF-8      
    reader.readAsText(fileToRead);
    reader.onload = loadHandler;
    reader.onerror = errorHandler;
}

export function replaceNumberStartingWith00(number, prefix = "+") {
    if (number && number.startsWith("00")) {
        number = number.replace("00", prefix)
    }
    return number
}

export function getPhoneNumberParsed(number) {
    var n = number
    try {
        n = parsePhoneNumberFromString(replaceNumberStartingWith00("" + n)).formatInternational()
    } catch (error) {
        // console.log("error: " + error)
    }
    
    return n
}

export function isValidPhoneNumber(number) {
    try {
        const phoneNumber = parsePhoneNumberFromString(replaceNumberStartingWith00("" + number))
        return phoneNumber.isPossible()
    } catch (error) {
        // console.log("error: " + error)
    }
    
    return false
}

export function whileTypingNumber(number) {
    try {
        const formatter = new AsYouType()
        var numberWithPrefixPlus = replaceNumberStartingWith00("" + number)

        return {
            numberFormatted: formatter.input(numberWithPrefixPlus),
            number: formatter.getNumber().number
        }
    } catch (error) {
        // console.log("error: " + error)
    }
    
    return {
        number: number,
        numberFormatted: number
    }
}

export function getNumberWithPrefixPlus(number) {
    if (number && number.startsWith("00")) {
        number = number.replace("00", "+")
    }
    return number
}

export function getNumberWithPrefix00(number) {
    if (number && number.startsWith("+")) {
        number = number.replace("+", "00")
    }
    return number
}

export function getPercentage(value = 1, total = 1) {
    return total === 0 || value === 0 ? 0 : Math.round(((value * 100) / total) * 100) / 100
}

export function getFriendlyError(errorCode) {
    switch (errorCode) {
        case 400: //Bad Request
            return "Your request data is invalid"
        case 401: //Unauthorized
        case 405: //Method Not Allowed
            return "You are not authorized to make this request. Please login again and try again."
        case 403: //Forbidden
            return "You do not have sufficient privileges to make this request."
        case 404: //Not Found
            return "Cannot found what you requested"
        case 408: //Request Timeout
            return "The request took too much time to process"
        default:
    }

    return "Something went wrong with your request."
}

export function getNowDateFormatted() {
    let now = new Date();
    let year = "" + now.getFullYear();
    let month = "" + (now.getMonth() + 1); if (month.length === 1) { month = "0" + month; }
    let day = "" + now.getDate(); if (day.length === 1) { day = "0" + day; }
    let hour = "" + now.getHours(); if (hour.length === 1) { hour = "0" + hour; }
    let minute = "" + now.getMinutes(); if (minute.length === 1) { minute = "0" + minute; }
    let second = "" + now.getSeconds(); if (second.length === 1) { second = "0" + second; }
    return year + "" + month + "" + day + "-" + hour + "" + minute + "" + second;
}

// eslint-disable-next-line no-extend-native
Array.prototype.unique = function() {
    var a = this.concat();
    for(var i=0; i<a.length; ++i) {
        for(var j=i+1; j<a.length; ++j) {
            if(a[i] === a[j])
                a.splice(j--, 1);
        }
    }

    return a;
}