import React, { useState, useEffect } from "react";
import { 
  Container,
  Row,
  Col,
  Fade,
  ButtonGroup,
  Button,
  Tooltip,
 } from "shards-react";
import { CSVLink } from "react-csv";
import PageTitle from "../../components/common/PageTitle";
import NotificationsSentTableCard from "../../components/table/NotificationsSentTableCard";
import { AxiosGET, API } from "../../utils/BackendAPI";
import * as Utils from "../../utils/Utils";
import CircularProgress from '@material-ui/core/CircularProgress';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const NotificationsSent = (props) => {
  const [dataToExport, setDataToExport] = useState();
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [allNotificationsSent, setAllNotificationsSent] = useState();
  const numberColumnID = "nsMyNumber"
  const usernameColumnID = "nsUsername"
  const pushIDColumnID = "nsPushID"
  const payloadColumnID = "nsPayload"
  const apnsResponseColumnID = "nsApnsResponse"
  const sentTimeColumnID = "nsSentTime"

  const NotificationsSentColumns = React.useMemo(
    () => [
      {
        Header: 'Notifications Sent Logs',
        columns: [
          {
            Header: 'Sent time',
            accessor: sentTimeColumnID,
          },
          {
            Header: 'Who sent',
            accessor: usernameColumnID,
          },
          {
            Header: 'Destination',
            accessor: numberColumnID,
          },
          {
            Header: 'nsMyNumberForSearchPlus',
            accessor: 'nsMyNumberForSearchPlus',
          },
          {
            Header: 'nsMyNumberForSearch00',
            accessor: 'nsMyNumberForSearch00',
          },
          {
            Header: 'nsMyNumberForSearchFormatted',
            accessor: 'nsMyNumberForSearchFormatted',
          },
          {
            Header: 'Push ID',
            accessor: pushIDColumnID,
          },
          {
            Header: 'Payload',
            accessor: payloadColumnID,
          },
          {
            Header: 'APNs Response',
            accessor: apnsResponseColumnID,
          },
        ],
      },
    ],
    []
  )

  const mainCardActions = (
    <Col lg="6" className="mb-2" style={{
      display: "flex",
      justifyContent: "flex-end"
    }}>
      <ButtonGroup size="sm">
        <CSVLink
          data={dataToExport || []}
          separator={";"}
          filename={"NotificationsSent.csv"}
        >
          <Button id="exportBtn" theme="secondary" onClick={() => {}}>
            <span className="text-white">
              <i className="material-icons">file_upload</i>
            </span>{" "}
            Export
          </Button>
          <Tooltip
            placement="bottom"
            open={tooltipOpen}
            target={"#exportBtn"}
            toggle={() => setTooltipOpen(!tooltipOpen)}
          >
            Export to a .csv file
          </Tooltip>
        </CSVLink>
      </ButtonGroup>
    </Col>
  )

  useEffect(() => {
    fnRenderTable()
  }, []);

  useEffect(() => {
    var dataToExportArr = []
    dataToExportArr.push(["Sent Time", "Who Sent", "Destination", "PushID", "Payload", "APNs Response", "Silent"])
    if (allNotificationsSent) {
      allNotificationsSent.forEach(item => {
        dataToExportArr.push([item.nsSentTime, item.nsUsername, ""+item.nsMyNumber, item.nsPushID, item.nsPayload, item.nsApnsResponse, (item.nsSilentNotification === "1" ? "yes" : (item.nsSilentNotification === "0" ? "no - popup" : "no-alert"))])
      })
    }

    setDataToExport(dataToExportArr)
  }, [allNotificationsSent])

  function fnRenderTable() {
    AxiosGET(API.ALL_NOTIFICATIONS_SENT.PATH, (data) => {
      data.map(d => {
        d = Object.assign(d, {
          nsMyNumberForSearchPlus: Utils.getNumberWithPrefixPlus(d.nsMyNumber),
          nsMyNumberForSearch00: Utils.getNumberWithPrefix00(d.nsMyNumber),
          nsMyNumberForSearchFormatted: Utils.getPhoneNumberParsed(d.nsMyNumber),
        })

        return d
      })
      setAllNotificationsSent(data)
    }, 
    (e) => toast.error("It was not possible to load the sent notifications"))
  }

  const header = (
    <Row noGutters className="page-header py-4">
      <PageTitle title="Notifications Sent" subtitle="Notification UI" className="text-sm-left mb-3" />
    </Row>
  )

  if (!allNotificationsSent) {
    return (
      <Container fluid className="main-content-container px-4">
        {header}
        <CircularProgress />
      </Container>
    )
  }

  return (
    <Container fluid className="main-content-container px-4">
      {header}
      <Fade>
        {
          allNotificationsSent &&
          <Row>
            <Col lg="12" className="mb-4">
              <NotificationsSentTableCard columns={NotificationsSentColumns} data={allNotificationsSent} title="Notifications Sent" numberColumnID={numberColumnID} usernameColumnID={usernameColumnID} pushIDColumnID={pushIDColumnID} payloadColumnID={payloadColumnID} apnsResponseColumnID={apnsResponseColumnID} sentTimeColumnID={sentTimeColumnID} mainCardActions={mainCardActions} />
            </Col>
          </Row>
        }
      </Fade>
  
    </Container>
  )
}

export default NotificationsSent;
