import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout, AuthenticationLayout } from "./layouts";

import Login from "./views/auth/Login";

export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/dashboard" />
  },
  {
    path: "/login",
    layout: AuthenticationLayout,
    // layout: DefaultLayout,
    component: Login
  },
];
