import React, { useState, useEffect } from "react";
import { 
  Container,
  Row,
  Col,
  Fade,
  Card,
  CardTitle,
  CardBody,
  CardFooter,
  ButtonGroup,
  Button,
  Modal, 
  ModalBody, 
  ModalHeader,
  ModalFooter,
  Nav, 
  NavItem, 
  NavLink,
  ListGroup,
  ListGroupItem,
  FormGroup,
  FormInput,
  FormFeedback,
} from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import BGEventHistorySettingsCodesEnabledTableCard from "../../components/table/BGEventHistorySettingsCodesEnabledTableCard"
import AppEventsSettinsCodesWorkingHoursTableCard from "../../components/table/AppEventsSettinsCodesWorkingHoursTableCard"
import AppEventsSettinsCodesBusinessDaysTableCard from "../../components/table/AppEventsSettinsCodesBusinessDaysTableCard"
import { AxiosGET, AxiosPOST, AxiosDELETE, API } from "../../utils/BackendAPI";
// import * as Utils from "../../utils/Utils"
import Select from 'react-select';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const ManageAlertSettingsCode = (props) => {
  // const [autoScroll, setAutoScroll] = useState(false)
  const [activeTab, setActiveTab] = useState(0);
  
  const [allSettingsCodesOptions, setAllSettingsCodesOptions] = useState([])

  const [enableAllSettingsCodesConfirmOpen, setEnableAllSettingsCodesConfirmOpen] = useState(false)
  const [disableAllSettingsCodesConfirmOpen, setDisableAllSettingsCodesConfirmOpen] = useState(false)

  // const [apnsErrors, setApnsErrors] = useState([])
  // const [apnsSuccess, setApnsSuccess] = useState([])

  const [settingsCodesHasItemsSelected, setSettingsCodesHasItemsSelected] = useState(false)

  const [allEventHistSettingsCodeEnabled, setAllEventHistSettingsCodeEnabled] = useState()
  const [disableBGEventSettingsCode, setDisableBGEventSettingsCode] = useState()
  const [disableBGEventSettingsCodeConfirmOpen, setDisableBGEventSettingsCodeConfirmOpen] = useState(false)

  const [selectedWorkingHours, setSelectedWorkingHours] = useState()
  const [selectedBusinessDays, setSelectedBusinessDays] = useState()

  const [validationWorkingHoursErrorText, setValidationWorkingHoursErrorText] = useState()
  const [validationBusinessDaysErrorText, setValidationBusinessDaysErrorText] = useState()

  const [allAppEventsSettinsCodesWorkingHours, setAllAppEventsSettinsCodesWorkingHours] = useState([])
  const [allAppEventsSettinsCodesBusinessDays, setAllAppEventsSettinsCodesBusinessDays] = useState([])

  const [allWorkingHours, setAllWorkingHours] = useState([])
  const [allBusinessDays, setAllBusinessDays] = useState([])
  
  const defaultGoToOptions = {value: "calendar", label: "Calendar screen"}
  const [goToOptions] = useState([
    {value: "keypad", label: "Keypad screen"},
    defaultGoToOptions,
    {value: "calendar_enablecallme", label: "CallMe enable popup"},
    {value: "calendar_callmetime", label: "CallMe call time popup"},
    {value: "calendar_notiftime", label: "Meeting notification time popup"},
    {value: "recents", label: "Recents screen"},
    {value: "speeddial", label: "Speed Dial screen"},
    {value: "dashboard", label: "Dashboard screen"},
    {value: "dashboard_mynumbers", label: "My Numbers screen"},
    {value: "dashboard_advsettings", label: "Advance Settings screen"},
    {value: "dashboard_settings", label: "Settings screen"},
    {value: "dashboard_onboarding", label: "OnBoarding screen"},
    {value: "dashboard_support", label: "Support screen"},
  ])

  const [formIsValid, setFormIsValid] = useState(true)
  const [title, setTitle] = useState()
  const [subtitle, setSubtitle] = useState()
  const [bodyContent, setBodyContent] = useState()
  const [selectedGoToOptions, setSelectedGoToOptions] = useState(defaultGoToOptions)

  // const scrollTo = (ref) => {
  //   if (ref && autoScroll) {
  //     ref.scrollIntoView({ behavior: 'smooth', block: 'start' })
  //     setAutoScroll(false)
  //   }
  // }
  
  const opsColumnName = "Actions"
  const settingsCodeEnabledColumnID = "scSettingsCode"

  const BGEventHistorySettingsCodesEnabledColumns = React.useMemo(
    () => [
      {
        Header: 'Settings Codes Enabled',
        columns: [
          {
            Header: 'Settings Code',
            accessor: settingsCodeEnabledColumnID,
          },
          {
            Header: 'Working Hours'
          },
          {
            Header: 'Business Days'
          },
        ],
      },
      {
        Header: ' ',
        columns: [
          {
            Header: opsColumnName,
            disableSortBy: true
          }
        ],
      },
    ],
    []
  )

  const AppEventsSettinsCodesWorkingHoursColumns = React.useMemo(
    () => [
      {
        Header: 'Working Hours',
        columns: [
          {
            Header: 'Begin Hour',
            accessor: "whHourBegin"
          },
          {
            Header: 'End Hour',
            accessor: "whHourEnd"
          },
        ],
      },
      {
        Header: ' ',
        columns: [
          {
            Header: opsColumnName,
            disableSortBy: true
          }
        ],
      },
    ],
    []
  )
  const AppEventsSettinsCodesBusinessDaysColumns = React.useMemo(
    () => [
      {
        Header: 'Business Days',
        columns: [
          {
            Header: 'Begin Day',
            accessor: "bdDayBegin"
          },
          {
            Header: 'End Day',
            accessor: "bdDayEnd"
          },
        ],
      },
      {
        Header: ' ',
        columns: [
          {
            Header: opsColumnName,
            disableSortBy: true
          }
        ],
      },
    ],
    []
  )

  useEffect(() => {
    getTablesContents()
    getAlertMsgContent()
  }, []);

  useEffect(() => {
    fillComboboxes()
  }, [allAppEventsSettinsCodesWorkingHours, allAppEventsSettinsCodesBusinessDays]);

  function getTablesContents() {
    AxiosGET(API.ALL_SETTINGS_CODES.PATH, (data) => {
      var newSettingsCodesOptions = []
      data.map(d => {
        newSettingsCodesOptions.push({value: d["scCode"], label: d["scDescription"]})
        return d
      })

      setAllSettingsCodesOptions(newSettingsCodesOptions)
    }, (e) => toast.error("It was not possible to get all settings codes"))

    AxiosGET(API.ALL_EVENTS_HISTORY_ENABLED_BY_SETTINGS_CODE.PATH, (data) => {
      setAllEventHistSettingsCodeEnabled(data)
    }, (e) => toast.error("It was not possible to get all settings codes enabled for bg events tracing"))

    AxiosGET(API.ALL_APP_EVENTS_WORKING_HOURS.PATH, (data) => {
      setAllAppEventsSettinsCodesWorkingHours(data)
    }, (e) => toast.error("It was not possible to get all working hours"))

    AxiosGET(API.ALL_APP_EVENTS_BUSINESS_DAYS.PATH, (data) => {
      setAllAppEventsSettinsCodesBusinessDays(data)
    }, (e) => toast.error("It was not possible to get all business days"))

  }

  function getAlertMsgContent() {
    AxiosGET(API.APP_ALERT_MESSAGE.PATH, (data) => {
      if (data) {
        data.forEach(d => {
          if (d) {
            setTitle(d.amTitle)
            setSubtitle(d.amSubtitle)
            setBodyContent(d.amBody)
            
            const gotoScreenArr = goToOptions.filter(el => el.value === d.amGotoScreen)
            setSelectedGoToOptions(gotoScreenArr && gotoScreenArr.length > 0 ? gotoScreenArr[0] : {})
          }
        })
      }
    }, (e) => toast.error("It was not possible to get all settings codes"))
  }

  function fillComboboxes() {
    if (allAppEventsSettinsCodesWorkingHours) {
      var arrWH = []
      allAppEventsSettinsCodesWorkingHours.forEach(e => {
        arrWH.push({value: e.whHourBegin + "-" + e.whHourEnd, label: e.whHourBegin + "h - " + e.whHourEnd + "h"})
      })
      setAllWorkingHours(arrWH)
    }

    if (allAppEventsSettinsCodesBusinessDays) {
      var arrBD = []
      allAppEventsSettinsCodesBusinessDays.forEach(e => {
        arrBD.push({value: e.bdDayBegin + "-" + e.bdDayEnd, label: translateBusinessDay(e.bdDayBegin) + " - " + translateBusinessDay(e.bdDayEnd)})
      })
      setAllBusinessDays(arrBD)
    }
  }

  function handleChangeSettingsCodeSelectionSettingsTab(selectedObj) {
    if (selectedObj && selectedObj.length > 0) {
      // var itemsProcessed = 0
      var dataArr = []
      selectedObj.forEach((el, idx) => {
        AxiosGET(API.GET_PUSH_IDS_BY_SETTINGS_CODE.PREFIX + el.value + API.GET_PUSH_IDS_BY_SETTINGS_CODE.SUFFIX, (data) => {
          dataArr.push(data)

          // itemsProcessed++

          //processWhenAllResponsesReceived(itemsProcessed, selectedObj.length, dataArr)

        }, (e) => toast.error("It was not possible to get all settings codes"))
      })
    } else {
      setValidationWorkingHoursErrorText()
      setValidationBusinessDaysErrorText()
    }

    setSettingsCodesHasItemsSelected(selectedObj)
  }
  
  function handleChangeWorkingHoursSettingsTab(sWorkingHours) {
    if (sWorkingHours) {
      setValidationWorkingHoursErrorText()
      setSelectedWorkingHours(sWorkingHours)
    } else {
      setSelectedWorkingHours()
    }
  }

  function handleChangeBusinessDaysSettingsTab(sBusinessDays) {
    if (sBusinessDays) {
      setValidationBusinessDaysErrorText()
      setSelectedBusinessDays(sBusinessDays)
    } else {
      setSelectedBusinessDays()
    }
  }

  function fnDisableObserverEventsHistorySettingsCode(settingsCode) {
    if (settingsCode) {
      setDisableBGEventSettingsCode(settingsCode)
      setDisableBGEventSettingsCodeConfirmOpen(true)
    }
  }

  function disableSelectedSettingsCodesForEventsHistory() {
    if (settingsCodesHasItemsSelected && settingsCodesHasItemsSelected.length > 0) {
      var successfullySent = []
      var errorReasons = []
      settingsCodesHasItemsSelected.forEach(el => {
        AxiosDELETE(API.BG_EVENT_HISTORY_SETTINGS_CODE.PATH + el.value, (result) => {
          if (result && result.status === 200) {
            successfullySent.push(el.label)
          } else {
            const errorReason = {settingsCode: el.label, reason: "It was not possible to process your request"}
            errorReasons.push(errorReason)
          }
          processFeedbackToUser(settingsCodesHasItemsSelected.length, successfullySent, errorReasons, true)
        }, (e) => {
          const errorReason = {settingsCode: el.label, reason: "It was not possible to process your request"}
          errorReasons.push(errorReason)
          processFeedbackToUser(settingsCodesHasItemsSelected.length, successfullySent, errorReasons, true)
        })
      })
    }
    setDisableAllSettingsCodesConfirmOpen()
  }

  function disableSelectedSettingCodeForEventsHistory() {
    if(disableBGEventSettingsCode) {
      AxiosDELETE(API.BG_EVENT_HISTORY_SETTINGS_CODE.PATH + disableBGEventSettingsCode, (result) => {
        if (result && result.status === 200) {
          toast.success("Setting codes tracing successfully disabled")
          getTablesContents()
        } else {
          toast.error("It was not possible to process your request")
        }
        setDisableBGEventSettingsCodeConfirmOpen(false)
      }, (e) => {
        toast.error("It was not possible to process your request")
        setDisableBGEventSettingsCodeConfirmOpen(false)
      })
    }
  }


  function enableAllSelectedSettingsCodesForEventsHistory () {
    if (settingsCodesHasItemsSelected && settingsCodesHasItemsSelected.length > 0) {
      var successfullySent = []
      var errorReasons = []
      settingsCodesHasItemsSelected.forEach(el => {
        const workingHourArr = selectedWorkingHours.value.split("-")
        const workingHourBegin = workingHourArr[0]
        const workingHourEnd = workingHourArr[1]

        const businessDayArr = selectedBusinessDays.value.split("-")
        const businessDayBegin = businessDayArr[0]
        const businessDayEnd = businessDayArr[1]

        const postParams = {
          workingHourBegin: parseInt(workingHourBegin), 
          workingHourEnd: parseInt(workingHourEnd),
          businessDayBegin: parseInt(businessDayBegin),
          businessDayEnd: parseInt(businessDayEnd),
        }

        AxiosPOST(API.BG_EVENT_HISTORY_SETTINGS_CODE.PATH + el.value, postParams, (result) => {
          if (result && result.status === 200) {
            successfullySent.push(el.label)
          } else {
            const errorReason = {settingsCode: el.label, reason: "It was not possible to process your request"}
            errorReasons.push(errorReason)
          }
          processFeedbackToUser(settingsCodesHasItemsSelected.length, successfullySent, errorReasons)
        }, (e) => {
          const errorReason = {settingsCode: el.label, reason: "It was not possible to process your request"}
          errorReasons.push(errorReason)
          processFeedbackToUser(settingsCodesHasItemsSelected.length, successfullySent, errorReasons)
        })
      })
    }
  }

  const processFeedbackToUser = (totalRequests, successfullySentArr, errorReasonsArr) => {
    if (errorReasonsArr != null && successfullySentArr != null && (totalRequests === successfullySentArr.length + errorReasonsArr.length)) {
      getTablesContents()

      if (successfullySentArr.length > 0) {
        if (successfullySentArr.length  === 1 && errorReasonsArr.length === 0) {
          toast.success("Push user tracing successfully enabled")
        } else if (successfullySentArr.length === 1 && errorReasonsArr.length > 0) {
          toast.success("There was 1 push user tracing successfully enabled")
          // setApnsSuccess(successfullySentArr)
        } else {
          toast.success("There were " + successfullySentArr.length + " push users tracing successfully enabled")
          // setApnsSuccess(successfullySentArr)
        }
      }

      if (errorReasonsArr.length > 0) {
        if (errorReasonsArr.length === 1 && successfullySentArr.length === 0) {
          toast.error(errorReasonsArr[0].reason)
        } else if (errorReasonsArr.length === 1 && successfullySentArr.length > 0) {
          toast.error("There was 1 push user tracing unsuccessfully enabled. See below for more details.")
          // setApnsErrors(errorReasonsArr)
        } else {
          toast.error("There were " + errorReasonsArr.length + " push users tracing unsuccessfully enabled. See below for more details.")
          // setApnsErrors(errorReasonsArr)
        }
      }
    }
  }

  function fnAddWorkingHours(begin, end) {
    if (begin >= 0 && end >= 0) {
      const postParams = {
        begin,
        end
      }
      AxiosPOST(API.APP_EVENTS_WORKING_HOURS.PATH, postParams, (result) => {
        if (result && result.status === 200) {
          toast.success("Working hour successfully created")

          AxiosGET(API.ALL_APP_EVENTS_WORKING_HOURS.PATH, (data) => {
            setAllAppEventsSettinsCodesWorkingHours(data)
          }, (e) => toast.error("It was not possible to get all working hours"))
        } else {
          toast.error("It was not possible to process your request")
        }
      }, (e) => {
        if (e && e.response && e.response.status === 409) {
          toast.warn(begin + "h - " + end + "h is already configured")
        } else {
          toast.error("It was not possible to process your request")
        }
      })
    }
  }

  function fnRemoveWorkingHours(whID) {
    if (whID) {
      if (window.confirm("Are you sure you want to remove this working hour?")) {
        AxiosDELETE(API.APP_EVENTS_WORKING_HOURS.PATH + "/"  + whID, (result) => {
          if (result && result.status === 200) {
            toast.success("Working hour successfully removed")
            AxiosGET(API.ALL_APP_EVENTS_WORKING_HOURS.PATH, (data) => {
              setAllAppEventsSettinsCodesWorkingHours(data)
            }, (e) => toast.error("It was not possible to get all working hours"))
          } else {
            toast.error("It was not possible to process your request")
          }
        }, (e) => {
          toast.error("It was not possible to process your request")
        })
      }
    }
  }

  function fnRemoveBusinessDays(bdID) {
    if (bdID) {
      if (window.confirm("Are you sure you want to remove this business days?")) {
        AxiosDELETE(API.APP_EVENTS_BUSINESS_DAYS.PATH + "/" + bdID, (result) => {
          if (result && result.status === 200) {
            toast.success("Working hour successfully removed")
            AxiosGET(API.APP_EVENTS_BUSINESS_DAYS.PATH, (data) => {
              setAllAppEventsSettinsCodesBusinessDays(data)
            }, (e) => toast.error("It was not possible to get all business days"))
          } else {
            toast.error("It was not possible to process your request")
          }
        }, (e) => {
          toast.error("It was not possible to process your request")
        })
      }
    }
  }

  function translateBusinessDay(day) {
    switch (day) {
      case 0: case "0": return "Sunday"
      case 1: case "1": return "Monday"
      case 2: case "2": return "Tuesday"
      case 3: case "3": return "Wednesday"
      case 4: case "4": return "Thursday"
      case 5: case "5": return "Friday"
      case 6: case "6": return "Saturday"
      default:
    }

    return ""
  }

  function fnAddBusinessDays(begin, end) {
    if (begin >= 0 && end >= 0) {
      const postParams = {
        begin,
        end
      }
      AxiosPOST(API.APP_EVENTS_BUSINESS_DAYS.PATH, postParams, (result) => {
        if (result && result.status === 200) {
          toast.success("Business day successfully created")

          AxiosGET(API.APP_EVENTS_BUSINESS_DAYS.PATH, (data) => {
            setAllAppEventsSettinsCodesBusinessDays(data)
          }, (e) => toast.error("It was not possible to get all business days"))
        } else {
          toast.error("It was not possible to process your request")
        }
      }, (e) => {
        if (e && e.response && e.response.status === 409) {
          toast.warn(translateBusinessDay(begin) + " - " + translateBusinessDay(end) + " is already configured")
        } else {
          toast.error("It was not possible to process your request")
        }
      })
    }
  }

  const handleChangeTitle = e => {
    setTitle(e.target.value)
    setFormIsValid(e.target.value ? true : false) 
  }

  const handleChangeSubtitle = e => {
    setSubtitle(e.target.value)
    //setFormIsValid(e.target.value ? true : false) 
  }

  const handleChangeBodyContent = e => {
    setBodyContent(e.target.value)
    setFormIsValid(e.target.value ? true : false) 
  }

  function handleChangeGoToSelection(selectedObj) {
    setSelectedGoToOptions(selectedObj)
  }

  const mainCardActions = (<></>)

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle title="Manage Alert Settings Codes" subtitle="Notification UI" className="text-sm-left mb-3" />
      </Row>

      <Modal open={enableAllSettingsCodesConfirmOpen ? true : false} toggle={() => {}} >
        <ModalHeader>
          <span className="primary">
            <i className="material-icons">library_add</i>
          </span>{" "}Enable All Settings Codes Selected?
        </ModalHeader>
        <ModalBody>
          <>
          <div><strong>Are you sure do you want to enable the Setting Code(s) for tracing the update events?</strong></div>
          </>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup size="sm">
            <Button
              size="sm"
              theme="success"
              onClick={() => {
                setEnableAllSettingsCodesConfirmOpen()
                enableAllSelectedSettingsCodesForEventsHistory()
              }}
            >
              {" "}
              <span className="white">
                <i className="material-icons">library_add</i>
              </span>{" "}
              Enable
            </Button>
            <Button
              size="sm"
              theme="white"
              onClick={() => {
                setEnableAllSettingsCodesConfirmOpen()
              }}
            >
              <span className="white">
                <i className="material-icons">close</i>
              </span>{" "}
            Cancel
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </Modal>

      <Modal open={disableAllSettingsCodesConfirmOpen ? true : false} toggle={() => {}} >
        <ModalHeader>
          <span className="primary">
            <i className="material-icons">library_add</i>
          </span>{" "}Disable Selected?
        </ModalHeader>
        <ModalBody>
          <>
          <div><strong>Are you sure do you want to disable the settings code(s) from tracing the update events?</strong></div>
          </>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup size="sm">
            <Button
              size="sm"
              theme="danger"
              onClick={() => {
                disableSelectedSettingsCodesForEventsHistory()
              }}
            >
              {" "}
              <span className="white">
                <i className="material-icons">delete</i>
              </span>{" "}
              Disable
            </Button>
            <Button
              size="sm"
              theme="white"
              onClick={() => {
                setDisableAllSettingsCodesConfirmOpen()
                // setSettingsCodeSelectedToDisable()
              }}
            >
              <span className="white">
                <i className="material-icons">close</i>
              </span>{" "}
            Cancel
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </Modal>

      <Modal open={disableBGEventSettingsCodeConfirmOpen ? true : false} toggle={() => {}} >
        <ModalHeader>
          <span className="primary">
            <i className="material-icons">library_add</i>
          </span>{" "}Disable Selected?
        </ModalHeader>
        <ModalBody>
          <>
          <div><strong>Are you sure do you want to disable this settings code from trace the events history timeline?</strong></div>
          <div>{disableBGEventSettingsCode}</div>
          </>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup size="sm">
            <Button
              size="sm"
              theme="danger"
              onClick={() => {
                disableSelectedSettingCodeForEventsHistory()
              }}
            >
              {" "}
              <span className="white">
                <i className="material-icons">delete</i>
              </span>{" "}
              Disable
            </Button>
            <Button
              size="sm"
              theme="white"
              onClick={() => {
                setDisableBGEventSettingsCode()
                setDisableBGEventSettingsCodeConfirmOpen()
              }}
            >
              <span className="white">
                <i className="material-icons">close</i>
              </span>{" "}
            Cancel
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </Modal>

      <Nav tabs style={{
        marginBottom: 20,
      }}>
        <NavItem>
          <NavLink active={activeTab === 0} href="#" onClick={() => setActiveTab(0)}>Manage Settings Codes</NavLink>
        </NavItem>
        <NavItem>
          <NavLink active={activeTab === 1} href="#" onClick={() => setActiveTab(1)}>Manage Work Hours</NavLink>
        </NavItem>
        <NavItem>
          <NavLink active={activeTab === 2} href="#" onClick={() => setActiveTab(2)}>Manage Business Days</NavLink>
        </NavItem>
        <NavItem>
          <NavLink active={activeTab === 3} href="#" onClick={() => setActiveTab(3)}>Manage Altert Notification Content</NavLink>
        </NavItem>
      </Nav>
  
      <Fade>
      {
        activeTab === 0 && allSettingsCodesOptions &&
        <>
          <Row>
            <Col lg="6" className="mb-4">
              <Card>
                <CardBody>
                  <CardTitle>Settings Codes</CardTitle>
                  <Row>
                    <Col lg="12">
                      <Select
                        // value={selectedGoToOptions}
                        onChange={handleChangeSettingsCodeSelectionSettingsTab}
                        options={allSettingsCodesOptions}
                        isSearchable
                        isClearable
                        isMulti
                        closeMenuOnSelect
                        placeholder={'Select the Settings Codes...'}
                        styles={{
                          multiValue: base => ({
                            ...base,
                            backgroundColor: '#5a6169',
                            color: "#ffffff"
                          }),
                          multiValueLabel: base => ({
                            ...base,
                            color: "#ffffff"
                          }),
                        }}
                      />
                    </Col>
                  </Row>
                  <Row style={{
                    marginTop: 10
                  }}>
                    <Col lg="6">
                      <Select
                        isDisabled={!settingsCodesHasItemsSelected || settingsCodesHasItemsSelected.length === 0} 
                        onChange={handleChangeWorkingHoursSettingsTab}
                        options={allWorkingHours}
                        isSearchable={false}
                        isClearable
                        isMulti={false}
                        closeMenuOnSelect
                        placeholder={'Select the Working Hours...'}
                        styles={{
                          multiValue: base => ({
                            ...base,
                            backgroundColor: '#5a6169',
                            color: "#ffffff"
                          }),
                          multiValueLabel: base => ({
                            ...base,
                            color: "#ffffff"
                          }),
                        }}
                      />
                    </Col>
                    <Col lg="6">
                      <Select
                        isDisabled={!settingsCodesHasItemsSelected || settingsCodesHasItemsSelected.length === 0} 
                        onChange={handleChangeBusinessDaysSettingsTab}
                        options={allBusinessDays}
                        isSearchable={false}
                        isClearable
                        isMulti={false}
                        closeMenuOnSelect
                        placeholder={'Select the Business Days...'}
                        styles={{
                          multiValue: base => ({
                            ...base,
                            backgroundColor: '#5a6169',
                            color: "#ffffff"
                          }),
                          multiValueLabel: base => ({
                            ...base,
                            color: "#ffffff"
                          }),
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <div style={{
                        marginTop: 10,
                        color: "red"
                      }}>{validationWorkingHoursErrorText}</div>
                    </Col>
                    <Col lg="6">
                      <div style={{
                        marginTop: 10,
                        color: "red"
                      }}>{validationBusinessDaysErrorText}</div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <ButtonGroup>
                    <Button 
                      disabled={!settingsCodesHasItemsSelected || settingsCodesHasItemsSelected.length === 0} 
                      theme="success" 
                      onClick={() => {
                        setValidationWorkingHoursErrorText()
                        setValidationBusinessDaysErrorText()
                        if (!selectedWorkingHours && !selectedBusinessDays) {
                          setValidationWorkingHoursErrorText("Please select the working hours")
                          setValidationBusinessDaysErrorText("Please select the business days")
                          return
                        } else if (!selectedWorkingHours) {
                          setValidationWorkingHoursErrorText("Please select the working hours")
                          return
                        } else if (!selectedBusinessDays) {
                          setValidationBusinessDaysErrorText("Please select the business days")
                          return
                        }

                        setEnableAllSettingsCodesConfirmOpen(true)
                      }}>
                      <span className="text-white"><i className="material-icons">check</i>{' '}Enable All Selected</span>
                      </Button>
                      <Button 
                        disabled={!settingsCodesHasItemsSelected || settingsCodesHasItemsSelected.length === 0} 
                        theme="danger" 
                        onClick={() => {
                          setValidationWorkingHoursErrorText()
                          setValidationBusinessDaysErrorText()
                          setDisableAllSettingsCodesConfirmOpen(true)
                        }}>
                        <span className="text-white"><i className="material-icons">delete</i>{' '}Disable All Selected</span>
                      </Button>
                  </ButtonGroup>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="8" className="mb-4">
              {
                allEventHistSettingsCodeEnabled && allEventHistSettingsCodeEnabled.length > 0 &&
                <BGEventHistorySettingsCodesEnabledTableCard columns={BGEventHistorySettingsCodesEnabledColumns} data={allEventHistSettingsCodeEnabled} title="Settings codes enabled" opsColumnName="Actions" nav={props.history} settingsCodeEnabledColumnID={settingsCodeEnabledColumnID} fnDisableObserverEventsHistorySettingsCode={fnDisableObserverEventsHistorySettingsCode} />
              }
            </Col>
          </Row>
        </>
      }
      {
        activeTab === 1 &&
        <Row>
          <Col lg="7" className="mb-4">
            <AppEventsSettinsCodesWorkingHoursTableCard columns={AppEventsSettinsCodesWorkingHoursColumns} data={allAppEventsSettinsCodesWorkingHours} title="Manage Working Hours" opsColumnName={opsColumnName} nav={props.history} mainCardActions={mainCardActions} fnAddWorkingHours={fnAddWorkingHours} fnRemoveWorkingHours={fnRemoveWorkingHours} />
          </Col>
        </Row>
      }
      {
        activeTab === 2 &&
        <Row>
          <Col lg="7" className="mb-4">
            <AppEventsSettinsCodesBusinessDaysTableCard columns={AppEventsSettinsCodesBusinessDaysColumns} data={allAppEventsSettinsCodesBusinessDays} title="Manage Business Days" opsColumnName={opsColumnName} nav={props.history} mainCardActions={mainCardActions} fnAddBusinessDays={fnAddBusinessDays} fnRemoveBusinessDays={fnRemoveBusinessDays} />
          </Col>
        </Row>
      }
      {
        activeTab === 3 &&
        <Row>
          <Col lg="4" className="mb-4">
            <Card>
              <CardBody>
                <CardTitle>Alert Notification Content</CardTitle>
                <ListGroup flush>
                  <ListGroupItem className="px-3">
                    <>
                      <FormGroup>
                        <FormInput defaultValue={title} maxLength={50} size="lg" className="mb-2" placeholder="title" required invalid={!title && !formIsValid} onChange={handleChangeTitle} />
                        <FormFeedback valid={formIsValid}>Title is mandatory.</FormFeedback>
                      </FormGroup>

                      <FormGroup>
                        <FormInput defaultValue={subtitle} maxLength={50} size="lg" className="mb-2" placeholder="subtitle" onChange={handleChangeSubtitle} />
                      </FormGroup>

                      <FormGroup>
                        <FormInput defaultValue={bodyContent} maxLength={500} size="lg" className="mb-2" placeholder="body" required invalid={!bodyContent && !formIsValid} onChange={handleChangeBodyContent} />
                        <FormFeedback valid={formIsValid}>Body Content is mandatory.</FormFeedback>
                      </FormGroup>

                      <FormGroup>
                        <Select
                          value={selectedGoToOptions}
                          onChange={handleChangeGoToSelection}
                          options={goToOptions}
                          // isMulti
                          isSearchable
                          isClearable
                          closeMenuOnSelect
                          placeholder={'Select the Go To screen...'}
                          styles={{
                            multiValue: base => ({
                              ...base,
                              backgroundColor: '#5a6169',
                              color: "#ffffff"
                            }),
                            multiValueLabel: base => ({
                              ...base,
                              color: "#ffffff"
                            }),
                          }}
                        />
                        {/* <FormFeedback valid={formIsValid && Object.keys(selectedGoToOptions).length > 0 && selectedGoToOptions.constructor === Object}>Go To is mandatory.</FormFeedback> */}
                      </FormGroup>
                    </>
                  </ListGroupItem>
                </ListGroup>
              </CardBody>
              <CardFooter className="border-top">
                <ButtonGroup size="sm">
                  <Button
                    size="sm"
                    theme="success"
                    onClick={() => {
                      if (title && bodyContent /*&& selectedGoToOptions*/) {
                        setFormIsValid(true)
                        if (window.confirm("Are you sure you want to update the alert message?")) {
                          const postParams = {
                            title,
                            subtitle,
                            body: bodyContent,
                            gotoScreen: selectedGoToOptions && selectedGoToOptions.value ? selectedGoToOptions.value : "calendar"
                          }
                          AxiosPOST(API.APP_ALERT_MESSAGE.PATH, postParams, (result) => {
                            if (result && result.status === 200) {
                              toast.success("Alert message was successfully updated")
                            } else {
                              toast.error("It was not possible to process your request")
                            }
                          }, (e) => {
                            toast.error("It was not possible to process your request")
                          })
                        }
                      } else {
                        setFormIsValid(false)
                      }
                    }}
                  >
                    <span className="primary">
                      <i className="material-icons">check</i>
                    </span>{" "}Update
                  </Button>
                </ButtonGroup>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      }
      </Fade>
  
    </Container>
  )
}

export default ManageAlertSettingsCode;
