import React from "react";
import PropTypes from "prop-types";
import Chart from "../utils/chart";

var timeFormat = 'YYYY-MM-DD';
// var color = Chart.helpers.color;

class ChartDashboardAVGAutoLastMonth extends React.Component {
  constructor(props) {
    super(props);

    this.canvasRef = React.createRef();
  }

  componentDidMount() {
    this._buildChart(this.props.globalData)
  }

  componentWillReceiveProps(props) {
    this._buildChart(props.globalData)
  }

  _buildChart(globalData) {
    var countsSuccess = []
    var countsFail = []

    var days = []

    Object.values(globalData.requestsLastMonth.success).forEach((req)=>{
      if (!days.includes(req.reqStatsDay)) days.push(req.reqStatsDay)
    });
    Object.values(globalData.requestsLastMonth.fail).forEach((req)=>{
      if (!days.includes(req.reqStatsDay)) days.push(req.reqStatsDay)
    });

    days.forEach((day) => {
      const elSuccess = Object.values(globalData.requestsLastMonth.success).filter(el => (el.reqStatsDay === day))
      countsSuccess.push(elSuccess && elSuccess.length > 0 ? elSuccess[0].reqCount : 0)

      const elFail = Object.values(globalData.requestsLastMonth.fail).filter(el => (el.reqStatsDay === day))
      countsFail.push(elFail && elFail.length > 0 ? elFail[0].reqCount : 0)
    })

    var chartData = {
      datasets: [
        {
					label: 'Success daily AVG',
					backgroundColor: 'rgba(0,123,255,0.7)',
          // borderColor: 'rgba(0,123,255,1)',
          pointBackgroundColor: '#ffffff',
          pointHoverBackgroundColor: 'rgb(0,123,255)',
          // borderWidth: 1.5,
          pointRadius: 0,
          pointHoverRadius: 3,
					data: countsSuccess,
        },
        {
					label: 'Fail daily AVG',
					backgroundColor: 'rgba(255,65,105,0.7)',
          // borderColor: 'rgba(255,65,105,1)',
          pointBackgroundColor: '#ffffff',
          pointHoverBackgroundColor: 'rgba(255,65,105,1)',
          borderDash: [3, 3],
          // borderWidth: 1,
          pointRadius: 0,
          pointHoverRadius: 2,
          pointBorderColor: 'rgba(255,65,105,1)',
					data: countsFail,
        }
      ],
      labels: days,
    }
    
    this._renderChart(chartData)

  }

  _renderChart(chartData) {
    const chartConfig = {
      type: "bar",
      data: chartData,
      options: {
        ...{
          legend: {
            position: "bottom",
            labels: {
              padding: 25,
              boxWidth: 20
            }
          },
          cutoutPercentage: 0,
          tooltips: {
            custom: false,
            mode: "index",
            position: "nearest"
          }
        },
        responsive: true,
        legend: {
          position: 'top'
        },
        elements: {
          line: {
            tension: 0.3
          },
          point: {
            radius: 0
          }
        },
        scales: {
          xAxes: [{
            stacked: true,
            gridLines: false,
            type: 'time',
						display: true,
						time: {
							format: timeFormat,
							// round: 'day'
						}
          }],
          yAxes: [{
            stacked: true
          }]
        },
        ...this.props.chartOptions
      }
    };

    this.setState({
      tasksMap: this.props.tasksMap
    })

    new Chart(this.canvasRef.current, chartConfig);
  }

  render() {
    return (
      <canvas
        height="220"
        ref={this.canvasRef}
        className="blog-users-by-device m-auto"
      />
    );
  }
}

ChartDashboardAVGAutoLastMonth.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
  /**
   * The chart config object.
   */
  chartConfig: PropTypes.object,
  /**
   * The Chart.js options.
   */
  chartOptions: PropTypes.object,
  /**
   * The chart data.
   */
  chartData: PropTypes.object
};

export default ChartDashboardAVGAutoLastMonth;
