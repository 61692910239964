import React, { useState, useEffect } from "react";
import { 
  Container,
  Row,
  Col,
  Fade,
 } from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import ActivityLogsTableCard from "../../components/table/ActivityLogsTableCard";
import { AxiosGET, API } from "../../utils/BackendAPI";
import * as Utils from "../../utils/Utils";
import CircularProgress from '@material-ui/core/CircularProgress';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const ActivityLogs = (props) => {
  const [allActivityLogs, setActivityLogs] = useState();
  const numberColumnID = "alMyNumber"
  const eventStateNameColumnID = "alEventStateName"
  const usernameColumnID = "alUsername"
  const activityColumnID = "alActivity"
  const eventTimeColumnID = "alEventTime"
  const ActivityLogsColumns = React.useMemo(
    () => [
      {
        Header: 'Activity Logs',
        columns: [
          {
            Header: 'Event time',
            accessor: eventTimeColumnID,
          },
          {
            Header: 'User',
            accessor: usernameColumnID,
          },
          {
            Header: 'My number',
            accessor: numberColumnID,
          },
          {
            Header: 'alMyNumberForSearchPlus',
            accessor: 'alMyNumberForSearchPlus',
          },
          {
            Header: 'alMyNumberForSearch00',
            accessor: 'alMyNumberForSearch00',
          },
          {
            Header: 'alMyNumberForSearchFormatted',
            accessor: 'alMyNumberForSearchFormatted',
          },
          {
            Header: 'Activity',
            accessor: activityColumnID,
          },
          {
            Header: 'Event state',
            accessor: eventStateNameColumnID,
          },
        ],
      },
    ],
    []
  )

  useEffect(() => {
    fnRenderTable()
  }, []);

  function fnRenderTable() {
    AxiosGET(API.ALL_ACTIVITY_LOGS.PATH, (data) => {
      data.map(d => {
        d = Object.assign(d, {
          alMyNumberForSearchPlus: Utils.getNumberWithPrefixPlus(d.alMyNumber),
          alMyNumberForSearch00: Utils.getNumberWithPrefix00(d.alMyNumber),
          alMyNumberForSearchFormatted: Utils.getPhoneNumberParsed(d.alMyNumber),
        })

        return d
      })
      setActivityLogs(data)
    }, 
    (e) => toast.error("It was not possible to load the activity logs"))
  }

  const header = (
    <Row noGutters className="page-header py-4">
      <PageTitle title="Activity logs" subtitle="Notification UI" className="text-sm-left mb-3" />
    </Row>
  )

  if (!allActivityLogs) {
    return (
      <Container fluid className="main-content-container px-4">
        {header}
        <CircularProgress />
      </Container>
    )
  }

  return (
    <Container fluid className="main-content-container px-4">
      {header}
      <Fade>
        {
          allActivityLogs &&
          <Row>
            <Col lg="12" className="mb-4">
              <ActivityLogsTableCard columns={ActivityLogsColumns} data={allActivityLogs} title="Activity logs" eventStateNameColumnID={eventStateNameColumnID} numberColumnID={numberColumnID} usernameColumnID={usernameColumnID} activityColumnID={activityColumnID} eventTimeColumnID={eventTimeColumnID} />
            </Col>
          </Row>
        }
      </Fade>
  
    </Container>
  )
}

export default ActivityLogs;
