import React from "react";
import { 
  Row,
  Col,
  Card,
  CardTitle,
  FormInput,
  CardBody,
  CardFooter,
  ButtonGroup,
  Button,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Fade,
 } from "shards-react";
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters, 
  useGlobalFilter
} from 'react-table'
import * as Utils from "../../utils/Utils";
import TableNavigation from './TableNavigation'
import 'react-toastify/dist/ReactToastify.min.css';

function GlobalFilterAndActions({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  globalFilteredRows,
  mainCardActions,
  disableSearch,
  selectAllFiltered,
  unselectAllFiltered
}) {
  const count = preGlobalFilteredRows.length

  return (
    <Row>
      <Col lg="7" className="mb-2">
        <InputGroup seamless>
          <InputGroupAddon type="prepend">
            <InputGroupText>
              <span className="primary">
                <i className="material-icons">search</i>
              </span>
            </InputGroupText>
          </InputGroupAddon>
          <FormInput 
            maxLength={100}
            value={globalFilter || ''}
            onChange={e => {
              setGlobalFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            placeholder={`${count} settings codes...`}
            disabled={disableSearch}
          />
        </InputGroup>
      </Col>
    </Row>
  )
}

function BGEventHistorySettingsCodesEnabledTableCard({ columns, data, title, opsColumnName, nav, settingsCodeEnabledColumnID, mainCardActions, fnRenderTable, selectAllFiltered, unselectAllFiltered, eventHistEnabledColumnID, fnDisableObserverEventsHistorySettingsCode }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    globalFilteredRows,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      title,
      initialState: { pageIndex: 0 },
    },
    useFilters, 
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  return (
    <>
      <Fade>
        <Card>
          <CardBody>
            <CardTitle>{title}</CardTitle>

            <div style={{marginBottom: 5}}>
              <GlobalFilterAndActions
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
                mainCardActions={mainCardActions}
                disableSearch={canPreviousPage}
                globalFilteredRows={globalFilteredRows}
                selectAllFiltered={selectAllFiltered}
                unselectAllFiltered={unselectAllFiltered}
              />
            </div>

            <table {...getTableProps()} className="table mb-0 DFETable">
              <thead className="bg-light">
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th {...column.getHeaderProps(column.getSortByToggleProps())} scope="col" className="border-0">
                        {column.render('Header')}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? <span className="text-secondary">{' '}<i className="material-icons">keyboard_arrow_down</i></span>
                              : <span className="text-secondary">{' '}<i className="material-icons">keyboard_arrow_up</i></span>
                            : ''}
                        </span>
                        {/* <div>{column.canFilter ? column.render('Filter') : null}</div> */}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()} style={{backgroundColor: Utils.isEven(i) ? "white" : "#FBFBFB"}}>
                      {row.cells.map(cell => {
                        if (cell.column.Header === opsColumnName) {
                          return (
                            <td {...cell.getCellProps()}>
                              <div className="actionsColumn">
                                <ButtonGroup size="sm">
                                  <Button theme="danger" onClick={() => {
                                    fnDisableObserverEventsHistorySettingsCode(cell.row.original[settingsCodeEnabledColumnID])
                                  }}>
                                    <span className="text-white"><i className="material-icons">delete</i>{' '}Disable</span>
                                  </Button> 
                                </ButtonGroup>
                              </div>
                            </td>
                          )
                        }
                        if (cell.column.id === settingsCodeEnabledColumnID) {
                          return (
                            <td {...cell.getCellProps()}>
                              <div>
                                {cell.row.original[settingsCodeEnabledColumnID]}
                              </div>
                            </td>
                          )
                        }
                        if (cell.column.Header === "Working Hours") {
                          return (
                            <td {...cell.getCellProps()}>
                              {
                                cell.row.original["scWorkingHourBegin"] + "h - " + cell.row.original["scWorkingHourEnd"] + "h"
                              }
                            </td>
                          )
                        }
                        if (cell.column.Header === "Business Days") {
                          let businessDayBegin = ""
                          let businessDayEnd = ""
                          switch (cell.row.original["scBusinessDayBegin"]) {
                            case "0": 
                              businessDayBegin = "Sunday"
                              break
                            case "1": 
                              businessDayBegin = "Monday"
                              break
                            case "2": 
                              businessDayBegin = "Tuesday"
                              break
                            case "3": 
                              businessDayBegin = "Wednesday"
                              break
                            case "4": 
                              businessDayBegin = "Thursday"
                              break
                            case "5": 
                              businessDayBegin = "Friday"
                              break
                            case "6": 
                              businessDayBegin = "Saturday"
                              break
                            default:
                          }

                          switch (cell.row.original["scBusinessDayEnd"]) {
                            case "0": 
                              businessDayEnd = "Sunday"
                              break
                            case "1": 
                              businessDayEnd = "Monday"
                              break
                            case "2": 
                              businessDayEnd = "Tuesday"
                              break
                            case "3": 
                              businessDayEnd = "Wednesday"
                              break
                            case "4": 
                              businessDayEnd = "Thursday"
                              break
                            case "5": 
                              businessDayEnd = "Friday"
                              break
                            case "6": 
                              businessDayEnd = "Saturday"
                              break
                            default:
                          }
                          return (
                            <td {...cell.getCellProps()}>
                              {
                                businessDayBegin + " - " + businessDayEnd + ""
                              }
                            </td>
                          )
                        }
                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </CardBody>
          <CardFooter>
            <TableNavigation 
              pageIndex={pageIndex} 
              gotoPage={gotoPage} 
              pageSize={pageSize} 
              pageOptions={pageOptions} 
              setPageSize={setPageSize} 
              canPreviousPage={canPreviousPage} 
              canNextPage={canNextPage}
              previousPage={previousPage} 
              nextPage={nextPage} 
              pageCount={pageCount} 
            />
          </CardFooter>
        </Card>
      </Fade>
    </>
  )
}

export default BGEventHistorySettingsCodesEnabledTableCard;
