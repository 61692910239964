import React from "react";
import { 
  Row,
  Col,
  Card,
  CardTitle,
  FormInput,
  CardBody,
  CardFooter,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Badge,
  Fade,
  ButtonGroup,
  Button
 } from "shards-react";
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters, 
  useGlobalFilter
} from 'react-table'
import * as Utils from "../../utils/Utils";
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-json-pretty/themes/monikai.css';
import TableNavigation from './TableNavigation';
import Moment from 'react-moment';

const CustomStepConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#1a696e',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#1a696e',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 2,
    borderRadius: 1,
  },
})(StepConnector);

function GlobalFilterAndActions({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  mainCardActions,
  disableSearch
}) {
  const count = preGlobalFilteredRows.length

  return (
    <Row>
      <Col lg="6" className="mb-2">
        <InputGroup seamless>
          <InputGroupAddon type="prepend">
            <InputGroupText>
              <span className="primary">
                <i className="material-icons">search</i>
              </span>
            </InputGroupText>
          </InputGroupAddon>
          <FormInput 
            maxLength={100}
            value={globalFilter || ''}
            onChange={e => {
              setGlobalFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            placeholder={`${count} automatic event processes...`}
            disabled={disableSearch}
          />
        </InputGroup>
      </Col>
      { mainCardActions }
    </Row>
  )
}

function NotificationsSilentSentTableCard({ mainCardActions, columns, data, title, startTimeColumnID, endTimeColumnID, successColumnID, failureColumnID, opsColumnName, openDetail, closeDetail, processSelected }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      title,
      initialState: { pageIndex: 0 },
    },
    useFilters, 
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  return (
    <>
      <Fade>
        <Card>
          <CardBody>
            <CardTitle>{title}</CardTitle>

            <div style={{marginBottom: 5}}>
              <GlobalFilterAndActions
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
                mainCardActions={mainCardActions}
                disableSearch={canPreviousPage}
              />
            </div>

            <table {...getTableProps()} className="table mb-0 DFETable tableWithActionsColumnIdx2 numberCol">
              <thead className="bg-light">
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th {...column.getHeaderProps(column.getSortByToggleProps())} scope="col"  className={"border-0" + (column.Header === "My Number" ? " numberCol" : "")}>
                        {column.render('Header')}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? <span className="text-secondary">{' '}<i className="material-icons">keyboard_arrow_down</i></span>
                              : <span className="text-secondary">{' '}<i className="material-icons">keyboard_arrow_up</i></span>
                            : ''}
                        </span>
                        {/* <div>{column.canFilter ? column.render('Filter') : null}</div> */}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()} style={{backgroundColor: Utils.isEven(i) ? "white" : "#FBFBFB"}}>
                      {row.cells.map(cell => {
                        if (cell.column.Header === opsColumnName) {
                          return (
                            <td {...cell.getCellProps()} style={{
                              verticalAlign: "middle"
                            }}>
                              <div className="actionsColumn">
                                <ButtonGroup size="sm">
                                  <Button
                                    size="sm"
                                    theme="success"
                                    onClick={() => {
                                      openDetail(cell.row.original[startTimeColumnID], true)
                                    }}
                                    disabled={processSelected && processSelected.startProcess === cell.row.original[startTimeColumnID] && processSelected.success}
                                  >
                                    <span className="white">
                                      <i className="material-icons">search</i>
                                    </span>{" "}
                                    {cell.row.original[successColumnID]} successful
                                  </Button>
                                  <Button
                                    size="sm"
                                    theme="danger"
                                    onClick={() => {
                                      openDetail(cell.row.original[startTimeColumnID], false)
                                    }}
                                    disabled={processSelected && processSelected.startProcess === cell.row.original[startTimeColumnID] && !processSelected.success}
                                  >
                                    <span className="white">
                                      <i className="material-icons">search</i>
                                    </span>{" "}
                                    {cell.row.original[failureColumnID]} failed
                                  </Button>
                                  {
                                    processSelected && processSelected.startProcess === cell.row.original[startTimeColumnID] &&
                                    <Button
                                      size="sm"
                                      theme="secondary"
                                      onClick={() => {
                                        closeDetail()
                                      }}
                                    >
                                      <span className="white">
                                        <i className="material-icons">close</i>
                                      </span>{" "}
                                      Close
                                    </Button>
                                  }
                                </ButtonGroup>
                              </div>
                            </td>
                          )
                        } else {
                          const processLifecycleTimelineSteps = [cell.row.original[startTimeColumnID], cell.row.original[endTimeColumnID]]
                          return (
                            <td {...cell.getCellProps()}>
                              <div style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyItems: "center",
                                justifyContent: "center",
                                alignContent: "center",
                                alignItems: "center",
                              }}>
                                <div className="processLifecycleStepsWrapper">
                                  <Stepper activeStep={2} connector={<CustomStepConnector />}>
                                    {processLifecycleTimelineSteps.map((label, i) => (
                                      <Step key={i}>
                                        <StepLabel>{label}</StepLabel>
                                      </Step>
                                    ))}
                                  </Stepper>
                                </div>
                                <div>
                                  <Badge outline pill theme="info">
                                    {"Elapsed time: "} 
                                    <strong>
                                      <Moment duration={cell.row.original[startTimeColumnID]}
                                              date={cell.row.original[endTimeColumnID]}
                                      />
                                    </strong>
                                  </Badge>
                                </div>
                              </div>
                            </td>
                          )
                        }
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </CardBody>
          <CardFooter>
            <TableNavigation 
              pageIndex={pageIndex} 
              gotoPage={gotoPage} 
              pageSize={pageSize} 
              pageOptions={pageOptions} 
              setPageSize={setPageSize} 
              canPreviousPage={canPreviousPage} 
              canNextPage={canNextPage}
              previousPage={previousPage} 
              nextPage={nextPage} 
              pageCount={pageCount} 
            />
          </CardFooter>
        </Card>
      </Fade>
    </>
  )
}

export default NotificationsSilentSentTableCard;
