import React, { useState, useEffect } from "react";
import { 
  Container,
  Row,
  Col,
  Fade,
  Card,
  CardTitle,
  CardBody,
  CardFooter,
  ButtonGroup,
  Button,
  Modal, 
  ModalBody, 
  ModalHeader,
  ModalFooter,
  Nav, 
  NavItem, 
  NavLink,
  Alert,
} from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import PushIDsTableCard from "../../components/table/PushIDsTableCard";
import PushIDsWithEventHistEnabledTableCard from "../../components/table/PushIDsWithEventHistEnabledTableCard";
import PushIDsSelectedTableCard from "../../components/table/PushIDsSelectedTableCard";
import EventsHistoryEnabledByPushIDTableCard from "../../components/table/EventsHistoryEnabledByPushIDTableCard"
import { AxiosGET, AxiosPOST, AxiosDELETE, API } from "../../utils/BackendAPI";
import * as Utils from "../../utils/Utils"
import Select from 'react-select';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const NotificationsTimeline = (props) => {
  const [autoScroll, setAutoScroll] = useState(false)
  const [activeTab, setActiveTab] = useState(0);
  
  const [allPushIds, setAllPushIds] = useState();
  const [allPushIdsSelected, setAllPushIdsSelected] = useState({})
  const [showingPushIDs, setShowingPushIDs] = useState()

  const [allPushIdsWithEventHistEnabled, setAllPushIdsWithEventHistEnabled] = useState()
  const [showingPushIDsWithEventHistEnabled, setShowingPushIDsWithEventHistEnabled] = useState()

  const [allEventHistEnabledByPushID, setAllEventHistEnabledByPushID] = useState()
  const [showingEventHistEnabledByPushID, setShowingEventHistEnabledByPushID] = useState()

  const [allSettingsCodesOptions, setAllSettingsCodesOptions] = useState([])

  const [enableAllConfirmOpen, setEnableAllConfirmOpen] = useState(false)
  const [disableAllConfirmOpen, setDisableAllConfirmOpen] = useState(false)

  const [pushIDSelectedToDisable, setPushIDSelectedToDisable] = useState()
  const [pushIDIDSelectedToViewAllEvents, setPushIDIDSelectedToViewAllEvents] = useState()
  
  const [apnsErrors, setApnsErrors] = useState([])
  const [apnsSuccess, setApnsSuccess] = useState([])

  const scrollTo = (ref) => {
    if (ref && autoScroll) {
      ref.scrollIntoView({ behavior: 'smooth', block: 'start' })
      setAutoScroll(false)
    }
  }
  
  const opsColumnName = "Actions"
  const pushIDIDColumnID = "piID"
  const pushIDColumnID = "piPushID"
  const myNumberOneColumnID = "piMyNumberOne"
  const settingsCodeColumnID = "piSettingsCode"
  const activeNumberColumnID = "piActiveNumber"
  const platformNameColumnID = "piPlatformName"
  const eventTimeColumnID = "piEventTime"
  const eventHistEnabledColumnID = "piEventHistoryEnabled"
  const PushIDsColumns = React.useMemo(
    () => [
      {
        Header: 'Push Users',
        columns: [
          {
            Header: 'OS',
            accessor: platformNameColumnID,
          },
          {
            Header: 'My Number 1',
            accessor: myNumberOneColumnID,
          },
          {
            Header: 'piMyNumberOneForSearchPlus',
            accessor: 'piMyNumberOneForSearchPlus',
          },
          {
            Header: 'piMyNumberOneForSearch00',
            accessor: 'piMyNumberOneForSearch00',
          },
          {
            Header: 'piMyNumberOneForSearchFormatted',
            accessor: 'piMyNumberOneForSearchFormatted',
          },
          {
            Header: 'Push ID',
            accessor: pushIDColumnID,
          },
          {
            Header: 'Settings Code',
            accessor: settingsCodeColumnID,
          },
          {
            Header: 'Active Number',
            accessor: activeNumberColumnID,
          },
          {
            Header: 'piActiveNumberForSearchPlus',
            accessor: 'piActiveNumberForSearchPlus',
          },
          {
            Header: 'piActiveNumberForSearch00',
            accessor: 'piActiveNumberForSearch00',
          },
          {
            Header: 'piActiveNumberForSearchFormatted',
            accessor: 'piActiveNumberForSearchFormatted',
          },
          {
            Header: 'Created at',
            accessor: eventTimeColumnID,
          }
        ],
      },
      {
        Header: ' ',
        columns: [
          {
            Header: opsColumnName,
            disableSortBy: true
          }
        ],
      },
    ],
    []
  )

  const PushIDsSelectedColumns = React.useMemo(
    () => [
      {
        Header: 'Push Users Selected',
        columns: [
          {
            Header: 'My Number 1',
            accessor: myNumberOneColumnID,
          },
          {
            Header: 'piMyNumberOneForSearchPlus',
            accessor: 'piMyNumberOneForSearchPlus',
          },
          {
            Header: 'piMyNumberOneForSearch00',
            accessor: 'piMyNumberOneForSearch00',
          },
          {
            Header: 'piMyNumberOneForSearchFormatted',
            accessor: 'piMyNumberOneForSearchFormatted',
          },
          {
            Header: 'Push ID',
            accessor: pushIDColumnID,
          }
        ],
      },
      {
        Header: ' ',
        columns: [
          {
            Header: opsColumnName,
            disableSortBy: true
          }
        ],
      },
    ],
    []
  )

  const notificationIDColumnID = "eheNotificationID"
  const startDateColumnID = "eheStartDate"
  const permissionsColumnID = "ehePermissions"
  const assistantColumnID = "eheAssistant"
  const readCalendarColumnID = "eheReadCalendar"
  const updatedColumnID = "eheUpdated"
  const endDateColumnID = "eheEndDate"
 
  const EventsHistoryEnabledByPushColumns = React.useMemo(
    () => [
      {
        Header: 'Events history enabled by push user',
        columns: [
          {
            Header: 'Notification Timeline',
            accessor: notificationIDColumnID,
          },
        ],
      }
    ],
    []
  )

  useEffect(() => {
    getTablesContents()
  }, []);

  function getTablesContents() {
    AxiosGET(API.ALL_PUSH_ID.PATH, (data) => {
      data.map(d => {
        d = Object.assign(d, {
          piMyNumberOneForSearchPlus: Utils.getNumberWithPrefixPlus(d.piMyNumberOne),
          piMyNumberOneForSearch00: Utils.getNumberWithPrefix00(d.piMyNumberOne),
          piMyNumberOneForSearchFormatted: Utils.getPhoneNumberParsed(d.piMyNumberOne),
          piActiveNumberForSearchPlus: Utils.getNumberWithPrefixPlus(d.piActiveNumber),
          piActiveNumberForSearch00: Utils.getNumberWithPrefix00(d.piActiveNumber),
          piActiveNumberForSearchFormatted: Utils.getPhoneNumberParsed(d.piActiveNumber)
        })

        return d
      })
      setAllPushIds(data)
      setShowingPushIDs(data)
    }, (e) => toast.error("It was not possible to get all push users"))

    AxiosGET(API.ALL_PUSH_ID_EVENT_HIST_ENABLED.PATH, (data) => {
      data.map(d => {
        d = Object.assign(d, {
          piMyNumberOneForSearchPlus: Utils.getNumberWithPrefixPlus(d.piMyNumberOne),
          piMyNumberOneForSearch00: Utils.getNumberWithPrefix00(d.piMyNumberOne),
          piMyNumberOneForSearchFormatted: Utils.getPhoneNumberParsed(d.piMyNumberOne),
          piActiveNumberForSearchPlus: Utils.getNumberWithPrefixPlus(d.piActiveNumber),
          piActiveNumberForSearch00: Utils.getNumberWithPrefix00(d.piActiveNumber),
          piActiveNumberForSearchFormatted: Utils.getPhoneNumberParsed(d.piActiveNumber)
        })

        return d
      })
      setAllPushIdsWithEventHistEnabled(data)
      setShowingPushIDsWithEventHistEnabled(data)
    }, (e) => toast.error("It was not possible to get all push users with event history enabled"))

    AxiosGET(API.ALL_SETTINGS_CODES.PATH, (data) => {
      var newSettingsCodesOptions = []
      data.map(d => {
        newSettingsCodesOptions.push({value: d["scCode"], label: d["scDescription"]})
        return d
      })

      setAllSettingsCodesOptions(newSettingsCodesOptions)
    }, (e) => toast.error("It was not possible to get all settings codes"))
    fnRenderTable()
  }

  function fnRenderTable(myNumberOneSelectedToggle, pushIDSelectedToggle) {
    var objClone = Object.assign({}, allPushIdsSelected)

    const exists = objClone && objClone[myNumberOneSelectedToggle]
    if (exists) {
      const newState = !objClone[myNumberOneSelectedToggle].selected
      objClone[myNumberOneSelectedToggle].selected = newState
    } else if (myNumberOneSelectedToggle && objClone) {
      objClone[myNumberOneSelectedToggle] = {}
      objClone[myNumberOneSelectedToggle]['selected'] = true
      objClone[myNumberOneSelectedToggle]['myNumberOne'] = myNumberOneSelectedToggle
      objClone[myNumberOneSelectedToggle]['pushID'] = pushIDSelectedToggle
    }
    
    setAllPushIdsSelected(objClone)
  }

  function fnViewAllTable(pushIDID) {
    setPushIDIDSelectedToViewAllEvents(pushIDID)
    AxiosGET(API.ALL_EVENTS_HISTORY_ENABLED_BY_PUSH_ID.PATH + "/" + pushIDID, (data) => {
      setAllEventHistEnabledByPushID(data)
      setShowingEventHistEnabledByPushID(data)
      setAutoScroll(true)
      scrollTo()
    }, (e) => toast.error("It was not possible to get all events history enabled by pushid ID"))
  }

  function closeDetail() {
    setPushIDIDSelectedToViewAllEvents()
    setAllEventHistEnabledByPushID()
    setShowingEventHistEnabledByPushID()
  }

  function pushIdsSelected() {
    if (allPushIdsSelected) {
      if (Object.keys(allPushIdsSelected).length > 0) {
        let count = 0
        for (const property in allPushIdsSelected) {
          if (allPushIdsSelected[property].selected) count++
        }
        return count
      }
    }

    return -1
  }

  function handleChangeSettingsCodeSelection(selectedObj) {
    if (selectedObj && selectedObj.length > 0) {
      var itemsProcessed = 0
      var dataArr = []
      selectedObj.forEach((el, idx) => {
        AxiosGET(API.GET_PUSH_IDS_BY_SETTINGS_CODE.PREFIX + el.value + API.GET_PUSH_IDS_BY_SETTINGS_CODE.SUFFIX, (data) => {
          dataArr.push(data)

          itemsProcessed++

          processWhenAllResponsesReceived(itemsProcessed, selectedObj.length, dataArr)

        }, (e) => toast.error("It was not possible to get all settings codes"))
      })
    } else {
      setShowingPushIDs(allPushIds)
    }
  }

  function processWhenAllResponsesReceived(iteration, total, dataArr) {
    var a = []
    if (iteration >= total && dataArr && dataArr.length > 0) {
      dataArr.forEach(el => {
        a = a.concat(el).unique()
      })

      setShowingPushIDs(a)
    }
  }
  
  function unselectAll() {
    if (showingPushIDs && showingPushIDs.length > 0) {
      var newAllPushIdsSelected = {}
      showingPushIDs.forEach(el => {
        newAllPushIdsSelected[el.piMyNumberOne] = {
          selected: false,
          myNumberOne: el.piMyNumberOne,
          pushID: el.piPushID
        }
      })

      setAllPushIdsSelected(newAllPushIdsSelected)
    }
  }

  function selectAllFiltered(dataFiltered) {
    if (dataFiltered && dataFiltered.length > 0) {
      var newAllPushIdsSelected = Object.assign({}, allPushIdsSelected)
      dataFiltered.forEach(el => {
        if (el.original) {
          newAllPushIdsSelected[el.original.piMyNumberOne] = {
            selected: true,
            myNumberOne: el.original.piMyNumberOne,
            pushID: el.original.piPushID
          }
        }
      })

      setAllPushIdsSelected(newAllPushIdsSelected)
    }
  }

  function unselectAllFiltered(dataFiltered) {
    if (dataFiltered && dataFiltered.length > 0) {
      var newAllPushIdsSelected = {}
      dataFiltered.forEach(el => {
        if (el.original) {
          newAllPushIdsSelected[el.original.piMyNumberOne] = {
            selected: false,
            myNumberOne: el.original.piMyNumberOne,
            pushID: el.original.piPushID
          }
        }
      })

      setAllPushIdsSelected(newAllPushIdsSelected)
    } 
  }

  function disableObserverEventsHistory(pushID) {
    if (pushID) {
      setPushIDSelectedToDisable(pushID)
      setDisableAllConfirmOpen(true)
    }
  }

  function disableSelectedPushIDForEventsHistory() {
    if(pushIDSelectedToDisable) {
      AxiosDELETE(API.PUSH_ID_EVENTS_OBSERVER.PATH + "/" + pushIDSelectedToDisable, (result) => {
        if (result && result.status === 200) {
          toast.success("Push users tracing successfully disabled")
          getTablesContents()
        } else {
          toast.error("It was not possible to process your request")
        }
        setPushIDSelectedToDisable()
        setDisableAllConfirmOpen(true)
        setDisableAllConfirmOpen(false)
      }, (e) => {
        toast.error("It was not possible to process your request")
        setPushIDSelectedToDisable()
        setDisableAllConfirmOpen(true)
        setDisableAllConfirmOpen(false)
      })
    }
  }

  function enableAllSelectedPushIDsForEventsHistory() {
    var allPushIdsSelectedArr = []
    if (pushIdsSelected() > 0) {
      allPushIdsSelectedArr = Object.keys(allPushIdsSelected).map(function(key) {
        const d = allPushIdsSelected[key]
        return {
            piPushID: d.pushID
        };
      })
      if (allPushIdsSelectedArr && allPushIdsSelectedArr.length > 0) {
        var successfullySent = []
        var errorReasons = []
        allPushIdsSelectedArr.forEach(el => {
          AxiosPOST(API.PUSH_ID_EVENTS_OBSERVER.PATH + "/" + el.piPushID, {}, (result) => {
            if (result && result.status === 200) {
              successfullySent.push(el.piPushID)
            } else {
              const errorReason = {pushID: el.piPushID, reason: "It was not possible to process your request"}
              errorReasons.push(errorReason)
            }
            processFeedbackToUser(allPushIdsSelectedArr.length, successfullySent, errorReasons)
          }, (e) => {
            const errorReason = {pushID: el.piPushID, reason: "It was not possible to process your request"}
            errorReasons.push(errorReason)
            processFeedbackToUser(allPushIdsSelectedArr.length, successfullySent, errorReasons)
          })
        })
      }
    }
  }

  const processFeedbackToUser = (totalRequests, successfullySentArr, errorReasonsArr) => {
    if (errorReasonsArr != null && successfullySentArr != null && (totalRequests === successfullySentArr.length + errorReasonsArr.length)) {
      getTablesContents()
      // unselectAll()
      setAllPushIdsSelected()

      if (successfullySentArr.length > 0) {
        if (successfullySentArr.length  === 1 && errorReasonsArr.length === 0) {
          toast.success("Push user tracing successfully enabled")
        } else if (successfullySentArr.length === 1 && errorReasonsArr.length > 0) {
          toast.success("There was 1 push user tracing successfully enabled")
          setApnsSuccess(successfullySentArr)
        } else {
          toast.success("There were " + successfullySentArr.length + " push users tracing successfully enabled")
          setApnsSuccess(successfullySentArr)
        }
      }

      if (errorReasonsArr.length > 0) {
        if (errorReasonsArr.length === 1 && successfullySentArr.length === 0) {
          toast.error(errorReasonsArr[0].reason)
        } else if (errorReasonsArr.length === 1 && successfullySentArr.length > 0) {
          toast.error("There was 1 push user tracing unsuccessfully enabled. See below for more details.")
          setApnsErrors(errorReasonsArr)
        } else {
          toast.error("There were " + errorReasonsArr.length + " push users tracing unsuccessfully enabled. See below for more details.")
          setApnsErrors(errorReasonsArr)
        }
      }
    }
  }

  var allPushIdsSelectedArr = []
  if (pushIdsSelected() > 0) {
    allPushIdsSelectedArr = Object.keys(allPushIdsSelected).map(function(key) {
      const d = allPushIdsSelected[key]
      return {
          piPushID: d.pushID, 
          piMyNumberOne: d.myNumberOne, 
          v: d.selected,
          piMyNumberOneForSearchPlus: Utils.getNumberWithPrefixPlus(d.myNumberOne),
          piMyNumberOneForSearch00: Utils.getNumberWithPrefix00(d.myNumberOne),
          piMyNumberOneForSearchFormatted: Utils.getPhoneNumberParsed(d.myNumberOne)
      };
    }).filter(el => el.v)
  }

  const mainCardActions = (<></>)

  const mainCardActionsEventsByPushID = (
    <Col lg="5" className="mb-2" style={{
      display: "flex",
      justifyContent: "flex-end"
    }}>
      <ButtonGroup size="sm" style={{marginLeft: 10}}>
        <Button
          size="sm"
          theme="secondary"
          onClick={() => closeDetail()}
        >
          <span className="white">
            <i className="material-icons">close</i>
          </span>{" "}
          Close
        </Button>
      </ButtonGroup>
    </Col>
  )

  const subCardActions = (
    <Col lg="5" className="mb-2" style={{
      display: "flex",
      justifyContent: "flex-end"
    }}>
      <ButtonGroup size="sm">
        <Button theme="warning" onClick={() => unselectAll()}>
          <span className="text-secondary">
            <i className="material-icons">layers_clear</i>
          </span>{" "}
          Unselect All
        </Button>
        <Button theme="success" onClick={() => setEnableAllConfirmOpen(true)}>
          <span className="text-white">
            <i className="material-icons">library_add</i>
          </span>{" "}
          Enable All Selected
        </Button>
      </ButtonGroup>
    </Col>
  )

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle title="Automatic Events Timeline" subtitle="Notification UI" className="text-sm-left mb-3" />
      </Row>

      <Modal open={enableAllConfirmOpen ? true : false} toggle={() => {}} >
        <ModalHeader>
          <span className="primary">
            <i className="material-icons">library_add</i>
          </span>{" "}Enable All Selected?
        </ModalHeader>
        <ModalBody>
          <>
          <div><strong>Are you sure do you want to enable the {pushIdsSelected() > 1 ? pushIdsSelected() + " push users " : " this push user"} for tracing the events history timeline?</strong></div>
          </>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup size="sm">
            <Button
              size="sm"
              theme="success"
              onClick={() => {
                setEnableAllConfirmOpen()
                enableAllSelectedPushIDsForEventsHistory()
              }}
            >
              {" "}
              <span className="white">
                <i className="material-icons">library_add</i>
              </span>{" "}
              Enable
            </Button>
            <Button
              size="sm"
              theme="white"
              onClick={() => {
                setEnableAllConfirmOpen()
              }}
            >
              <span className="white">
                <i className="material-icons">close</i>
              </span>{" "}
            Cancel
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </Modal>

      <Modal open={disableAllConfirmOpen ? true : false} toggle={() => {}} >
        <ModalHeader>
          <span className="primary">
            <i className="material-icons">library_add</i>
          </span>{" "}Disable Selected?
        </ModalHeader>
        <ModalBody>
          <>
          <div><strong>Are you sure do you want to disable this push user from trace the events history timeline?</strong></div>
          <div className="ellipsisWordSM">{pushIDSelectedToDisable}</div>
          </>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup size="sm">
            <Button
              size="sm"
              theme="danger"
              onClick={() => {
                disableSelectedPushIDForEventsHistory()
              }}
            >
              {" "}
              <span className="white">
                <i className="material-icons">delete</i>
              </span>{" "}
              Disable
            </Button>
            <Button
              size="sm"
              theme="white"
              onClick={() => {
                setDisableAllConfirmOpen()
                setPushIDSelectedToDisable()
              }}
            >
              <span className="white">
                <i className="material-icons">close</i>
              </span>{" "}
            Cancel
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </Modal>

      <Nav tabs style={{
        marginBottom: 20,
      }}>
        <NavItem>
          <NavLink active={activeTab === 0} href="#" onClick={() => setActiveTab(0)}>
            Automatic Events Push Timeline
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink active={activeTab === 1} href="#" onClick={() => setActiveTab(1)}>Manage Traced Timelines</NavLink>
        </NavItem>
      </Nav>
  
      <Fade>
      {
        activeTab === 1 && allSettingsCodesOptions &&
        <>
          <Row>
            <Col lg="4" className="mb-4">
              <Card>
                <CardBody>
                  <CardTitle>Settings Codes</CardTitle>
                  <Select
                    // value={selectedGoToOptions}
                    onChange={handleChangeSettingsCodeSelection}
                    options={allSettingsCodesOptions}
                    isSearchable
                    isClearable
                    isMulti
                    closeMenuOnSelect
                    placeholder={'Select...'}
                    styles={{
                      multiValue: base => ({
                        ...base,
                        backgroundColor: '#5a6169',
                        color: "#ffffff"
                      }),
                      multiValueLabel: base => ({
                        ...base,
                        color: "#ffffff"
                      }),
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      }
      {
        activeTab === 1 && showingPushIDs &&
        <>
          <Row>
            <Col lg="12" className="mb-4">
              <PushIDsTableCard columns={PushIDsColumns} data={showingPushIDs} title="Push users" opsColumnName="Actions" nav={props.history} pushIDColumnID={pushIDColumnID} myNumberOneColumnID={myNumberOneColumnID} settingsCodeColumnID={settingsCodeColumnID} activeNumberColumnID={activeNumberColumnID} platformNameColumnID={platformNameColumnID} eventTimeColumnID={eventTimeColumnID} fnRenderTable={fnRenderTable} allPushIdsSelected={allPushIdsSelected} mainCardActions={mainCardActions} selectAllFiltered={selectAllFiltered} unselectAllFiltered={unselectAllFiltered} eventHistEnabledColumnID={eventHistEnabledColumnID} fnDisableObserverEventsHistory={disableObserverEventsHistory} />
            </Col>
          </Row>
          <Row>
            {
              apnsSuccess && apnsSuccess.length > 0 &&
              <Col lg="6" className="mb-4">
                <Card>
                  <CardBody>
                    <CardTitle>Successful push users enabled</CardTitle>
                    {
                      apnsSuccess.map((success, idx) => (
                        <Alert key={idx} theme="success" open={apnsSuccess.length > 0}>
                          {success}
                        </Alert>
                      ))
                    }
                  </CardBody>
                  <CardFooter>
                    <ButtonGroup size="sm">
                      <Button theme="secondary" onClick={() => setApnsSuccess()}>
                        <span className="text-white">
                          <i className="material-icons">close</i>
                        </span>{" "}
                        Close
                      </Button>
                    </ButtonGroup>
                  </CardFooter>
                </Card>
              </Col>
            }
            {
              apnsErrors && apnsErrors.length > 0 &&
              <Col lg="6" className="mb-4">
                <Card>
                  <CardBody>
                  <CardTitle>Unsuccessful push users enabled</CardTitle>
                    {
                      apnsErrors.map((error, idx) => (
                        <Alert key={idx} theme="danger" open={apnsErrors.length > 0}>
                          <strong>{error.reason}</strong> {error.pushID}
                        </Alert>
                      ))
                    }
                  </CardBody>
                  <CardFooter>
                    <ButtonGroup size="sm">
                      <Button theme="secondary" onClick={() => setApnsErrors()}>
                        <span className="text-white">
                          <i className="material-icons">close</i>
                        </span>{" "}
                        Close
                      </Button>
                    </ButtonGroup>
                  </CardFooter>
                </Card>
              </Col>
            }
          </Row>
          <Row>
            {
              pushIdsSelected() > 0 &&
              <>
                <Col lg="12" className="mb-4">
                  <PushIDsSelectedTableCard columns={PushIDsSelectedColumns} data={allPushIdsSelectedArr} title="Push Users Selected" opsColumnName="Actions" pushIDColumnID={pushIDColumnID} myNumberOneColumnID={myNumberOneColumnID} fnRenderTable={fnRenderTable} allPushIdsSelected={allPushIdsSelectedArr} mainCardActions={subCardActions} />
                </Col>
              </>
            }
          </Row>
        </>
      }
      {
        activeTab === 0 && showingPushIDsWithEventHistEnabled &&
        <>
          <Row>
            <Col lg="12" className="mb-4">
              <PushIDsWithEventHistEnabledTableCard columns={PushIDsColumns} data={allPushIdsWithEventHistEnabled} title="Users with the traced timeline" opsColumnName="Actions" nav={props.history} pushIDIDColumnID={pushIDIDColumnID} pushIDColumnID={pushIDColumnID} myNumberOneColumnID={myNumberOneColumnID} settingsCodeColumnID={settingsCodeColumnID} activeNumberColumnID={activeNumberColumnID} platformNameColumnID={platformNameColumnID} eventTimeColumnID={eventTimeColumnID} viewAllTable={fnViewAllTable} allPushIdsSelected={allPushIdsSelected} mainCardActions={mainCardActions} selectAllFiltered={selectAllFiltered} unselectAllFiltered={unselectAllFiltered} eventHistEnabledColumnID={eventHistEnabledColumnID} fnDisableObserverEventsHistory={disableObserverEventsHistory} pushIDIDSelectedToViewAllEvents={pushIDIDSelectedToViewAllEvents} fnCloseDetail={closeDetail} />
            </Col>
          </Row>
          <div ref={scrollTo}>
            <Row>
              {
                showingEventHistEnabledByPushID &&
                <>
                  <Col lg="12" className="mb-4">
                    <EventsHistoryEnabledByPushIDTableCard columns={EventsHistoryEnabledByPushColumns} data={allEventHistEnabledByPushID} title="Events history enabled by push user" mainCardActions={mainCardActionsEventsByPushID} notificationIDColumnID={notificationIDColumnID} startDateColumnID={startDateColumnID} permissionsColumnID={permissionsColumnID} assistantColumnID={assistantColumnID} readCalendarColumnID={readCalendarColumnID} updatedColumnID={updatedColumnID} endDateColumnID={endDateColumnID} />
                  </Col>
                </>
              }
            </Row>
          </div>
        </>
      }
      </Fade>
  
    </Container>
  )
}

export default NotificationsTimeline;
