import React, { useState, useEffect } from "react";
import { 
  Container,
  Row,
  Col,
  Fade,
  Card,
  CardTitle,
  CardBody,
  CardFooter,
  ButtonGroup,
  Button,
  ListGroup,
  ListGroupItem,
  FormGroup,
  FormInput,
  FormFeedback,
  Modal, 
  ModalBody, 
  ModalHeader,
  ModalFooter,
  Alert,
  FormCheckbox,
  Tooltip,
} from "shards-react";
import { CSVLink } from "react-csv";
import PageTitle from "../../components/common/PageTitle";
import PushIDsTableCard from "../../components/table/PushIDsTableCard";
import PushIDsSelectedTableCard from "../../components/table/PushIDsSelectedTableCard";
import { AxiosGET, AxiosPOST, API } from "../../utils/BackendAPI";
import * as Utils from "../../utils/Utils"
import Select from 'react-select';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const PushIDs = (props) => {
  const [allPushIds, setAllPushIds] = useState();
  const [allPushIdsSelected, setAllPushIdsSelected] = useState({})

  const [allSettingsCodesOptions, setAllSettingsCodesOptions] = useState([])
  const [showingPushIDs, setShowingPushIDs] = useState()

  const [silentNotification, setSilentNotification] = useState(false)

  const [dataToExport, setDataToExport] = useState("")
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const [sendModalConfirmOpen, setSendModalConfirmOpen] = useState(false)
  const [clearModalConfirmOpen, setClearModalConfirmOpen] = useState(false)
  const [formIsValid, setFormIsValid] = useState(true)
  const [topic, setTopic] = useState("pt.voipit.call-it-travel")
  const [title, setTitle] = useState()
  const [subtitle, setSubtitle] = useState()
  const [bodyContent, setBodyContent] = useState()
  const [goToOptions] = useState([
    {value: "keypad", label: "Keypad screen"},
    {value: "calendar", label: "Calendar screen"},
    {value: "calendar_enablecallme", label: "CallMe enable popup"},
    {value: "calendar_callmetime", label: "CallMe call time popup"},
    {value: "calendar_notiftime", label: "Meeting notification time popup"},
    {value: "recents", label: "Recents screen"},
    {value: "speeddial", label: "Speed Dial screen"},
    {value: "dashboard", label: "Dashboard screen"},
    {value: "dashboard_mynumbers", label: "My Numbers screen"},
    {value: "dashboard_advsettings", label: "Advance Settings screen"},
    {value: "dashboard_settings", label: "Settings screen"},
    {value: "dashboard_onboarding", label: "OnBoarding screen"},
    {value: "dashboard_support", label: "Support screen"},
  ])
  const [selectedGoToOptions, setSelectedGoToOptions] = useState({})
  const [apnsErrors, setApnsErrors] = useState([])
  const [apnsSuccess, setApnsSuccess] = useState([])

  const opsColumnName = "Actions"
  const pushIDColumnID = "piPushID"
  const myNumberOneColumnID = "piMyNumberOne"
  const settingsCodeColumnID = "piSettingsCode"
  const activeNumberColumnID = "piActiveNumber"
  const platformNameColumnID = "piPlatformName"
  const eventTimeColumnID = "piEventTime"
  const PushIDsColumns = React.useMemo(
    () => [
      {
        Header: 'Push Users',
        columns: [
          {
            Header: 'OS',
            accessor: platformNameColumnID,
          },
          {
            Header: 'My Number 1',
            accessor: myNumberOneColumnID,
          },
          {
            Header: 'piMyNumberOneForSearchPlus',
            accessor: 'piMyNumberOneForSearchPlus',
          },
          {
            Header: 'piMyNumberOneForSearch00',
            accessor: 'piMyNumberOneForSearch00',
          },
          {
            Header: 'piMyNumberOneForSearchFormatted',
            accessor: 'piMyNumberOneForSearchFormatted',
          },
          {
            Header: 'Push ID',
            accessor: pushIDColumnID,
          },
          {
            Header: 'Settings Code',
            accessor: settingsCodeColumnID,
          },
          {
            Header: 'Active Number',
            accessor: activeNumberColumnID,
          },
          {
            Header: 'piActiveNumberForSearchPlus',
            accessor: 'piActiveNumberForSearchPlus',
          },
          {
            Header: 'piActiveNumberForSearch00',
            accessor: 'piActiveNumberForSearch00',
          },
          {
            Header: 'piActiveNumberForSearchFormatted',
            accessor: 'piActiveNumberForSearchFormatted',
          },
          {
            Header: 'Created at',
            accessor: eventTimeColumnID,
          }
        ],
      },
      {
        Header: ' ',
        columns: [
          {
            Header: opsColumnName,
            disableSortBy: true
          }
        ],
      },
    ],
    []
  )

  const PushIDsSelectedColumns = React.useMemo(
    () => [
      {
        Header: 'Push Users Selected',
        columns: [
          {
            Header: 'My Number 1',
            accessor: myNumberOneColumnID,
          },
          {
            Header: 'piMyNumberOneForSearchPlus',
            accessor: 'piMyNumberOneForSearchPlus',
          },
          {
            Header: 'piMyNumberOneForSearch00',
            accessor: 'piMyNumberOneForSearch00',
          },
          {
            Header: 'piMyNumberOneForSearchFormatted',
            accessor: 'piMyNumberOneForSearchFormatted',
          },
          {
            Header: 'Push ID',
            accessor: pushIDColumnID,
          }
        ],
      },
      {
        Header: ' ',
        columns: [
          {
            Header: opsColumnName,
            disableSortBy: true
          }
        ],
      },
    ],
    []
  )

  useEffect(() => {
    AxiosGET(API.ALL_PUSH_ID.PATH, (data) => {
      data.map(d => {
        d = Object.assign(d, {
          piMyNumberOneForSearchPlus: Utils.getNumberWithPrefixPlus(d.piMyNumberOne),
          piMyNumberOneForSearch00: Utils.getNumberWithPrefix00(d.piMyNumberOne),
          piMyNumberOneForSearchFormatted: Utils.getPhoneNumberParsed(d.piMyNumberOne),
          piActiveNumberForSearchPlus: Utils.getNumberWithPrefixPlus(d.piActiveNumber),
          piActiveNumberForSearch00: Utils.getNumberWithPrefix00(d.piActiveNumber),
          piActiveNumberForSearchFormatted: Utils.getPhoneNumberParsed(d.piActiveNumber)
        })

        return d
      })
      setAllPushIds(data)
      setShowingPushIDs(data)
    }, (e) => toast.error("It was not possible to get all push users's"))

    AxiosGET(API.ALL_SETTINGS_CODES.PATH, (data) => {
      var newSettingsCodesOptions = []
      data.map(d => {
        newSettingsCodesOptions.push({value: d["scCode"], label: d["scDescription"]})
        return d
      })

      setAllSettingsCodesOptions(newSettingsCodesOptions)
    }, (e) => toast.error("It was not possible to get all settings codes"))
    fnRenderTable()
  }, []);

  useEffect(() => {
    var dataToExportArr = []
    dataToExportArr.push(["OS", "My Number", "Push ID", "Settings Code", "Active Number", "Created At"])
    if (allPushIds) {
      allPushIds.forEach(item => {
        dataToExportArr.push([item.piPlatformName, item.piMyNumberOne, item.piPushID, item.piSettingsCode, item.piActiveNumber, item.piEventTime])
      })
    }

    setDataToExport(dataToExportArr)
  }, [allPushIds]);

  function fnRenderTable(myNumberOneSelectedToggle, pushIDSelectedToggle) {
    var objClone = Object.assign({}, allPushIdsSelected)

    const exists = objClone && objClone[myNumberOneSelectedToggle]
    if (exists) {
      const newState = !objClone[myNumberOneSelectedToggle].selected
      objClone[myNumberOneSelectedToggle].selected = newState
    } else if (myNumberOneSelectedToggle && objClone) {
      objClone[myNumberOneSelectedToggle] = {}
      objClone[myNumberOneSelectedToggle]['selected'] = true
      objClone[myNumberOneSelectedToggle]['myNumberOne'] = myNumberOneSelectedToggle
      objClone[myNumberOneSelectedToggle]['pushID'] = pushIDSelectedToggle
    }
    
    setAllPushIdsSelected(objClone)
  }

  function pushIdsSelected() {
    if (allPushIdsSelected) {
      if (Object.keys(allPushIdsSelected).length > 0) {
        let count = 0
        for (const property in allPushIdsSelected) {
          if (allPushIdsSelected[property].selected) count++
        }
        return count
      }
    }

    return -1
  }

  function sendNotification() {
    if (silentNotification || (topic && title && bodyContent /*&& selectedGoToOptions*/)) {
      setFormIsValid(true)

      var allPushIdsSelectedArr = []
      if (pushIdsSelected() > 0) {
        allPushIdsSelectedArr = Object.keys(allPushIdsSelected).map(function(key) {
          const d = allPushIdsSelected[key]
          return {
              piPushID: d.pushID, 
              piMyNumberOne: d.myNumberOne, 
              v: d.selected
          };
        }).filter(el => el.v)

        if (allPushIdsSelectedArr && allPushIdsSelectedArr.length > 0) {
          var successfullySent = []
          var errorReasons = []
          allPushIdsSelectedArr.forEach(el => {
            const params = {
              deviceToken: el.piPushID,
              apnsTopic: topic,
              title: silentNotification ? 'silent' : title,
              subtitle: silentNotification ? 'silent' : subtitle,
              bodyContent: silentNotification ? 'silent' : bodyContent,
              gotoDestination: selectedGoToOptions && selectedGoToOptions.value ? selectedGoToOptions.value : "keypad",
              myNumber: el.piMyNumberOne,
              silentNotification: silentNotification ? 1 : 0
            }

            AxiosPOST(API.SEND_NOTIFICATION.PATH, params, (result) => {
              if (result && result.data.statusCodeResponseAPNs && result.data.statusCodeResponseAPNs === 200) {
                successfullySent.push(el.piPushID)
              } else {
                const errorReason = {pushID: el.piPushID, reason: translateAPNsError(result.data.reason)}
                errorReasons.push(errorReason)
              }
              processFeedbackToUser(allPushIdsSelectedArr.length, successfullySent, errorReasons)
            }, (e) => {
              const errorReason = {pushID: el.piPushID, reason: translateAPNsError("unknown")}
              errorReasons.push(errorReason)
              processFeedbackToUser(allPushIdsSelectedArr.length, successfullySent, errorReasons)
            })
          })
        }
      } else {
        setFormIsValid(false)
      }
    }
  }

  const processFeedbackToUser = (totalRequests, successfullySentArr, errorReasonsArr) => {
    if (errorReasonsArr != null && successfullySentArr != null && (totalRequests === successfullySentArr.length + errorReasonsArr.length)) {

      if (successfullySentArr.length > 0) {
        if (successfullySentArr.length  === 1 && errorReasonsArr.length === 0) {
          toast.success("Notification was successfully sent")
        } else if (successfullySentArr.length === 1 && errorReasonsArr.length > 0) {
          toast.success("There was 1 notification successfully sent. See below for more details.")
          setApnsSuccess(successfullySentArr)
        } else {
          toast.success("There were " + successfullySentArr.length + " notifications successfully sent. See below for more details.")
          setApnsSuccess(successfullySentArr)
        }
      }

      if (errorReasonsArr.length > 0) {
        if (errorReasonsArr.length === 1 && successfullySentArr.length === 0) {
          toast.error(errorReasonsArr[0].reason)
        } else if (errorReasonsArr.length === 1 && successfullySentArr.length > 0) {
          toast.error("There was 1 notification unsuccessfully sent. See below for more details.")
          setApnsErrors(errorReasonsArr)
        } else {
          toast.error("There were " + errorReasonsArr.length + " notifications unsuccessfully sent. See below for more details.")
          setApnsErrors(errorReasonsArr)
        }
      }
    }
  }

  const translateAPNsError = e => {
    switch (e) {
      case "BadDeviceToken": return "Push ID token is invalid (BadDeviceToken)"
      case "unknown": return "It was not possible to process your request (" + e + ")"
      default: return e
    }
  }

  const handleChangeTopic = e => {
    setTopic(e.target.value)
    setFormIsValid(e.target.value ? true : false) 
  }

  const handleChangeTitle = e => {
    setTitle(e.target.value)
    setFormIsValid(e.target.value ? true : false) 
  }

  const handleChangeSubtitle = e => {
    setSubtitle(e.target.value)
  }

  const handleChangeBodyContent = e => {
    setBodyContent(e.target.value)
    setFormIsValid(e.target.value ? true : false) 
  }

  function handleChangeGoToSelection(selectedObj) {
    setSelectedGoToOptions(selectedObj)
  }

  function handleChangeSettingsCodeSelection(selectedObj) {
    if (selectedObj && selectedObj.length > 0) {
      var itemsProcessed = 0
      var dataArr = []
      selectedObj.forEach((el, idx) => {
        AxiosGET(API.GET_PUSH_IDS_BY_SETTINGS_CODE.PREFIX + el.value + API.GET_PUSH_IDS_BY_SETTINGS_CODE.SUFFIX, (data) => {
          dataArr.push(data)

          itemsProcessed++

          processWhenAllResponsesReceived(itemsProcessed, selectedObj.length, dataArr)

        }, (e) => toast.error("It was not possible to get all settings codes"))
      })
    } else {
      setShowingPushIDs(allPushIds)
    }
  }

  function processWhenAllResponsesReceived(iteration, total, dataArr) {
    var a = []
    if (iteration >= total && dataArr && dataArr.length > 0) {
      dataArr.forEach(el => {
        a = a.concat(el).unique()
      })

      setShowingPushIDs(a)
    }
  }

  function unselectAll() {
    if (showingPushIDs && showingPushIDs.length > 0) {
      var newAllPushIdsSelected = {}
      showingPushIDs.forEach(el => {
        newAllPushIdsSelected[el.piMyNumberOne] = {
          selected: false,
          myNumberOne: el.piMyNumberOne,
          pushID: el.piPushID
        }
      })

      setAllPushIdsSelected(newAllPushIdsSelected)
    }
  }

  function selectAllFiltered(dataFiltered) {
    if (dataFiltered && dataFiltered.length > 0) {
      var newAllPushIdsSelected = Object.assign({}, allPushIdsSelected)
      dataFiltered.forEach(el => {
        if (el.original) {
          newAllPushIdsSelected[el.original.piMyNumberOne] = {
            selected: true,
            myNumberOne: el.original.piMyNumberOne,
            pushID: el.original.piPushID
          }
        }
      })

      setAllPushIdsSelected(newAllPushIdsSelected)
    }
  }

  function unselectAllFiltered(dataFiltered) {
    if (dataFiltered && dataFiltered.length > 0) {
      var newAllPushIdsSelected = {}
      dataFiltered.forEach(el => {
        if (el.original) {
          newAllPushIdsSelected[el.original.piMyNumberOne] = {
            selected: false,
            myNumberOne: el.original.piMyNumberOne,
            pushID: el.original.piPushID
          }
        }
      })

      setAllPushIdsSelected(newAllPushIdsSelected)
    } 
  }

  var allPushIdsSelectedArr = []
  if (pushIdsSelected() > 0) {
    allPushIdsSelectedArr = Object.keys(allPushIdsSelected).map(function(key) {
      const d = allPushIdsSelected[key]
      return {
          piPushID: d.pushID, 
          piMyNumberOne: d.myNumberOne, 
          v: d.selected,
          piMyNumberOneForSearchPlus: Utils.getNumberWithPrefixPlus(d.myNumberOne),
          piMyNumberOneForSearch00: Utils.getNumberWithPrefix00(d.myNumberOne),
          piMyNumberOneForSearchFormatted: Utils.getPhoneNumberParsed(d.myNumberOne)
      };
    }).filter(el => el.v)
  }

  const mainCardActions = (
      <ButtonGroup size="sm" style={{marginLeft: 10}}>
        <CSVLink
          data={dataToExport}
          filename={"PushIDs.csv"}
        >
          <Button id="exportBtn" theme="secondary" onClick={() => {}}>
            <span className="text-white">
              <i className="material-icons">file_upload</i>
            </span>{" "}
            Export
          </Button>
          <Tooltip
            placement="bottom"
            open={tooltipOpen}
            target={"#exportBtn"}
            toggle={() => setTooltipOpen(!tooltipOpen)}
          >
            Export to a .csv file
          </Tooltip>
        </CSVLink>
      </ButtonGroup>
  )

  const subCardActions = (
    <Col lg="5" className="mb-2" style={{
      display: "flex",
      justifyContent: "flex-end"
    }}>
      <ButtonGroup size="sm">
        <Button theme="warning" onClick={() => unselectAll()}>
          <span className="text-secondary">
            <i className="material-icons">layers_clear</i>
          </span>{" "}
          Unselect All
        </Button>
      </ButtonGroup>
    </Col>
  )

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle title="Push Users" subtitle="Notification UI" className="text-sm-left mb-3" />
      </Row>

      <Modal open={sendModalConfirmOpen ? true : false} toggle={() => {}} >
        <ModalHeader>
          <span className="primary">
            <i className="material-icons">tap_and_play</i>
          </span>{" "}Send notification?
        </ModalHeader>
        <ModalBody>
          <>
          <div><strong>Are you sure do you want to send the {silentNotification?" silent " : ""} notification{pushIdsSelected() > 1 ? " to " + pushIdsSelected() + " device tokens" : ""}?</strong></div>
          {
            !silentNotification &&
            <>
              <br></br>
              <p>
                Topic: <strong>{topic}</strong><br></br>
                Title: <strong>{title}</strong><br></br>
                Description: <strong>{subtitle}</strong><br></br>
                Body Content: <strong>{bodyContent}</strong><br></br>
                Go To: <strong>{selectedGoToOptions && selectedGoToOptions.label ? selectedGoToOptions.label : ""}</strong><br></br>
              </p>
              {/* <div>
                <JSONPretty 
                  id="json-pretty" 
                  data={bodyContent} 
                  onJSONPrettyError={e => {}}
                  mainStyle="line-height:1.3;color:#7F7F7F;background:#FFFFFF;overflow:auto;"
                  keyStyle="color:#4360BB"
                  valueStyle="color:#5074E3"
                  stringStyle="color:#595C64"
                  booleanStyle="color:#5F4997"
                  errorStyle="color:#B21E1E"
                />
                </div> */}
            </>
          }
          </>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup size="sm">
            <Button
              size="sm"
              theme="primary"
              onClick={() => {
                if (silentNotification) {
                  setSendModalConfirmOpen()
                  sendNotification()
                } else if (title && bodyContent) {
                  setSendModalConfirmOpen()
                  sendNotification()
                }
              }}
            >
              Send
              {" "}
              <span className="white">
                <i className="material-icons">send</i>
              </span>
            </Button>
            <Button
              size="sm"
              theme="white"
              onClick={() => {
                setSendModalConfirmOpen()
              }}
            >
              <span className="white">
                <i className="material-icons">close</i>
              </span>{" "}
            Cancel
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </Modal>
      <Modal open={clearModalConfirmOpen ? true : false} toggle={() => {}} >
        <ModalHeader>
          <span className="primary">
            <i className="material-icons">clear</i>
          </span>{" "}Clear form?
        </ModalHeader>
        <ModalBody>
          <>
          <div>You will loose all the data in your form.</div>
          <div><strong>Are you sure do you want to clear the form?</strong></div>
          </>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup size="sm">
            <Button
              size="sm"
              theme="primary"
              onClick={() => {
                setTitle()
                setSubtitle()
                setBodyContent()
                setClearModalConfirmOpen()
              }}
            >
              <span className="white">
                <i className="material-icons">clear</i>
              </span>{" "}
            Clear
            </Button>
            <Button
              size="sm"
              theme="white"
              onClick={() => {
                setClearModalConfirmOpen()
              }}
            >
            Cancel
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </Modal>
  
      <Fade>
        {
          allSettingsCodesOptions &&
          <>
            <Row>
              <Col lg="4" className="mb-4">
                <Card>
                  <CardBody>
                    <CardTitle>Settings Codes</CardTitle>
                    <Select
                      // value={selectedGoToOptions}
                      onChange={handleChangeSettingsCodeSelection}
                      options={allSettingsCodesOptions}
                      isSearchable
                      isClearable
                      isMulti
                      closeMenuOnSelect
                      placeholder={'Select...'}
                      styles={{
                        multiValue: base => ({
                          ...base,
                          backgroundColor: '#5a6169',
                          color: "#ffffff"
                        }),
                        multiValueLabel: base => ({
                          ...base,
                          color: "#ffffff"
                        }),
                      }}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        }
        {
          showingPushIDs &&
          <>
            <Row>
              <Col lg="12" className="mb-4">
                <PushIDsTableCard columns={PushIDsColumns} data={showingPushIDs} title="Push Users" opsColumnName="Actions" nav={props.history} pushIDColumnID={pushIDColumnID} myNumberOneColumnID={myNumberOneColumnID} settingsCodeColumnID={settingsCodeColumnID} activeNumberColumnID={activeNumberColumnID} platformNameColumnID={platformNameColumnID} eventTimeColumnID={eventTimeColumnID} fnRenderTable={fnRenderTable} allPushIdsSelected={allPushIdsSelected} mainCardActions={mainCardActions} selectAllFiltered={selectAllFiltered} unselectAllFiltered={unselectAllFiltered} />
              </Col>
            </Row>
            <Row>
              {
                pushIdsSelected() > 0 &&
                <>
                  <Col lg="8" className="mb-4">
                    <PushIDsSelectedTableCard columns={PushIDsSelectedColumns} data={allPushIdsSelectedArr} title="Push Users Selected" opsColumnName="Actions" pushIDColumnID={pushIDColumnID} myNumberOneColumnID={myNumberOneColumnID} fnRenderTable={fnRenderTable} allPushIdsSelected={allPushIdsSelectedArr} mainCardActions={subCardActions} />
                  </Col>
                  <Col lg="4" className="mb-4">
                    <Card>
                      <CardBody>
                        <CardTitle>Send Notification</CardTitle>
                        <ListGroup flush>
                          <ListGroupItem className="px-3">
                            <div style={{marginBottom: 20}}>
                              <FormCheckbox
                                toggle
                                checked={silentNotification}
                                onChange={e => setSilentNotification(!silentNotification)}
                              >
                                Silent Notification
                              </FormCheckbox>
                            </div>
                            {
                              silentNotification &&
                              <div>
                                <p>
                                  <span className="text-primary">
                                    <i className="material-icons">info</i>
                                  </span>{' '}This silent notification has a default message payload
                                </p>
                              </div>
                            }
                            {
                              !silentNotification &&
                              <>
                                <FormGroup>
                                  <FormInput disabled value={topic} size="lg" className="mb-2" placeholder="topic" required invalid={!topic && !formIsValid} onChange={handleChangeTopic} />
                                  <FormFeedback valid={formIsValid}>Topic is mandatory.</FormFeedback>
                                </FormGroup>

                                <FormGroup>
                                  <FormInput maxLength={50} size="lg" className="mb-2" placeholder="title" required invalid={!title && !formIsValid} onChange={handleChangeTitle} />
                                  <FormFeedback valid={formIsValid}>Title is mandatory.</FormFeedback>
                                </FormGroup>

                                <FormGroup>
                                  <FormInput maxLength={50} size="lg" className="mb-2" placeholder="subtitle" onChange={handleChangeSubtitle} />
                                </FormGroup>

                                <FormGroup>
                                  <FormInput maxLength={500} size="lg" className="mb-2" placeholder="body" required invalid={!bodyContent && !formIsValid} onChange={handleChangeBodyContent} />
                                  <FormFeedback valid={formIsValid}>Body Content is mandatory.</FormFeedback>
                                </FormGroup>

                                <FormGroup>
                                  <Select
                                    // value={selectedGoToOptions}
                                    onChange={handleChangeGoToSelection}
                                    options={goToOptions}
                                    // isMulti
                                    isSearchable
                                    isClearable
                                    closeMenuOnSelect
                                    placeholder={'Select the Go To screen...'}
                                    styles={{
                                      multiValue: base => ({
                                        ...base,
                                        backgroundColor: '#5a6169',
                                        color: "#ffffff"
                                      }),
                                      multiValueLabel: base => ({
                                        ...base,
                                        color: "#ffffff"
                                      }),
                                    }}
                                  />
                                  {/* <FormFeedback valid={formIsValid && Object.keys(selectedGoToOptions).length > 0 && selectedGoToOptions.constructor === Object}>Go To is mandatory.</FormFeedback> */}
                                </FormGroup>
                              </>
                            }
                          </ListGroupItem>
                        </ListGroup>
                        
                        {
                          apnsSuccess && apnsSuccess.length > 0 && apnsSuccess.map((success, idx) => (
                            <Alert key={idx} theme="success" open={apnsSuccess.length > 0}>
                              {success}
                            </Alert>
                          ))
                        }
                        {
                          apnsErrors && apnsErrors.length > 0 && apnsErrors.map((error, idx) => (
                            <Alert key={idx} theme="danger" open={apnsErrors.length > 0}>
                              <strong>{error.reason}</strong> {error.pushID}
                            </Alert>
                          ))
                        }
                        {
                          ((apnsErrors && apnsErrors.length > 0) || (apnsSuccess && apnsSuccess.length > 0)) && 
                          <ButtonGroup size="sm">
                            <Button
                              size="sm"
                              theme="white"
                              onClick={() => {
                                setApnsSuccess()
                                setApnsErrors()
                              }}
                            >
                              Clear
                              {" "}
                              <span className="white">
                                <i className="material-icons">clear</i>
                              </span>
                            </Button>
                          </ButtonGroup>
                        }
                      </CardBody>
                      <CardFooter className="border-top">
                      <ButtonGroup size="sm">
                        <Button
                          size="sm"
                          theme="primary"
                          onClick={() => {
                            if (silentNotification) {
                              setFormIsValid(true)
                              setSendModalConfirmOpen(true)
                            } else if (topic && title && bodyContent /*&& selectedGoToOptions*/) {
                              setFormIsValid(true)
                              setSendModalConfirmOpen(true)
                            } else {
                              setFormIsValid(false)
                            }
                          }}
                        >
                          Send Notification
                          {" "}
                          <span className="primary">
                            <i className="material-icons">send</i>
                          </span>
                        </Button>
                        {/* <Button
                          size="sm"
                          theme="white"
                          onClick={() => {
                            if (title || subtitle || bodyContent) {
                              setClearModalConfirmOpen(true)
                            }
                          }}
                        >
                          <span className="white">
                            <i className="material-icons">clear</i>
                          </span>{" "}
                        Clear
                        </Button> */}
                      </ButtonGroup>
                    </CardFooter>
                    </Card>
                  </Col>
                </>
              }
            </Row>
          </>
        }
      </Fade>
  
    </Container>
  )
}

export default PushIDs;
