import React, { useState, useEffect } from "react";
import { 
  Container, 
  Row, 
  Col,
  Fade,
  Card, 
  CardTitle, 
  CardBody, 
  CardFooter,
  CardHeader,
  Nav, 
  NavItem,
  NavLink,
} from "shards-react";
import { AxiosGET, API } from "../../utils/BackendAPI";
import PageTitle from "../../components/common/PageTitle";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import ChartDashboardAVGAutoLastMonth from '../../charts/ChartDashboardAVGAutoLastMonth'
import ChartDashboardAVGAutoObserverLastMonth from '../../charts/ChartDashboardAVGAutoObserverLastMonth'
import CircularProgress from '@material-ui/core/CircularProgress';

const Dashboard = () => {
  const [dashboardStats, setDashboardStats] = useState()

  useEffect(() => {
    AxiosGET(API.DASHBOARD_STATS.PATH, (data) => setDashboardStats(data), (error) => toast.error("It was not possible to get the dashboard stats"))
  }, []);

  const header = (
    <Row noGutters className="page-header py-4">
      <PageTitle title="Dashboard" subtitle="Notification UI" className="text-sm-left mb-3" />
    </Row>
  )

  if (!dashboardStats) {
    return (
      <Container fluid className="main-content-container px-4">
        {header}
        <CircularProgress />
      </Container>
    )
  }

  return (
    <Container fluid className="main-content-container px-4">
      {header}
      <Fade>
        <Row>
          <Col lg="5" className="mb-4">
            <Card small className="mb-4">
              <CardHeader>
                <CardTitle>Automatic events acceptance on external server</CardTitle>
                <div>Daily average (last 30 days)</div>
              </CardHeader>
              <CardBody className="p-0 pb-3">
                <ChartDashboardAVGAutoLastMonth globalData={dashboardStats.dailyAutoNotificationsStats} />
              </CardBody>
              <CardFooter className="border-top">
                <Nav>
                  <NavItem>
                    <NavLink active href="/notifications-silent-sent">
                      View More &rarr;
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="5" className="mb-4">
            <Card small className="mb-4">
              <CardHeader>
                <CardTitle>Automatic events traced</CardTitle>
                <div>By last successful stage | last 30 days average %</div>
                
              </CardHeader>
              <CardBody className="p-0 pb-3">
                <ChartDashboardAVGAutoObserverLastMonth globalData={dashboardStats.dailyAutoNotificationsStagesStats} />
              </CardBody>
              <CardFooter className="border-top">
                <Nav>
                  <NavItem>
                    <NavLink active href="/notifications-timeline">
                      View More &rarr;
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Fade>
    </Container>
  )
}

export default Dashboard;
