import React from "react";
import { 
  FormInput,
  ButtonGroup,
  Button,
  FormSelect,
 } from "shards-react";

const TableNavigation = ({pageIndex, gotoPage, pageSize, pageOptions, setPageSize, canPreviousPage, canNextPage, previousPage, nextPage, pageCount}) => {

    return (
        <div className="DFETablePagination">
          <div>
            <span className="paginationGoToPage">
              Go to page:{' '}
              <FormInput 
                maxLength={100}
                size="sm" 
                type="number" 
                defaultValue={pageIndex + 1} 
                onChange={e => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0
                  gotoPage(page)
                }} 
              />
            </span>
            <FormSelect
              size="sm"
              value={pageSize}
              onChange={e => {
                setPageSize(Number(e.target.value))
              }}
              style={{
                display: "inline-block",
                width: "100px",
                marginLeft: "10px"
              }}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </FormSelect>
          </div>
          <div>
            <span className="paginationState">
              Page{' '}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
            </span>
            <ButtonGroup size="sm" className="navButtons">
              <Button theme="white" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                <span className="text-light">
                  <i className="material-icons">skip_previous</i>
                </span>
              </Button>
              <Button theme="white" onClick={() => previousPage()} disabled={!canPreviousPage}>
                <span className="text-light">
                  <i className="material-icons">navigate_before</i>
                </span>
              </Button>
              <Button theme="white" onClick={() => nextPage()} disabled={!canNextPage}>
                <span className="text-light">
                  <i className="material-icons">navigate_next</i>
                </span>
              </Button>
              <Button theme="white" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                <span className="text-light">
                  <i className="material-icons">skip_next</i>
                </span>
              </Button>
            </ButtonGroup>
          </div>
      </div>
    )
}

export default TableNavigation;